import React, { useState, useEffect } from "react";
import ServicesCard from "../../../components/DashboardComp/ServiceCard";
import {
  offer1Black,
  offer2,
  offer3,
  offer4,
  offer5,
  offer6,
  offer6B,
} from "../../../constant";
import { useSelector, useDispatch } from "react-redux";
import { createBrowserHistory } from "history";
import { useNavigate } from "react-router-dom";
import {
  GetAllService,
  GetNotification,
  UpdateNotification,
} from "../../../network/Network";
import {
  AllServices,
  NotificationApi,
} from "../../../redux/actions/AuthAction";
import { SpinnerCircular } from "spinners-react";
import ReactPaginate from "react-paginate";

function ServiceType(props) {
  const history = createBrowserHistory();
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const token = useSelector((state) => state.AuthReducer.token);
  const [allServiceData, setAllServiceData] = useState([]);
  const [spinLoad, setSpinLoad] = useState(false);

  const [notification, setNotification] = useState([]);
  const [serviceId, setServiceId] = useState("");
  const [selectedService, setSelectedService] = useState([]);

  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  let limit = 10;

  const handlePageClick = async (data) => {
    console.log(data.selected);
    setCurrentPage(data?.selected + 1);
  };

  //  == Get Notification ==
  useEffect(() => {
    GetNotification(currentPage, token)
      .then((res) => {
        console.log(res);
        const total = res?.data?.response?.data?.total;
        setPageCount(Math.ceil(total / limit));
        setNotification(res?.data?.response?.data?.data);
        dispatch(NotificationApi(res?.data?.response?.data?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const selectHandler = (item) => {
    setServiceId(item?.id);
    setSelectedService(item);
    console.log(serviceId);
  };

  const ServiceTypeHandler = () => {
    console.log(serviceId);
    Navigate("/dashboard/placeorder", {
      state: { id: serviceId, data: selectedService },
    });
  };

  //  == All Services ==
  useEffect(() => {
    setSpinLoad(true);
    GetAllService(currentPage, token)
      .then((res) => {
        console.log(res);
        const total = res?.data?.response?.data?.total;
        setPageCount(Math.ceil(total / limit));
        setAllServiceData(res?.data?.response?.data?.data);
        dispatch(AllServices(res?.data?.response?.data?.data));
        setSpinLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinLoad(false);
      });
  }, [currentPage]);

  console.log(allServiceData);
  return (
    <>
      <div className="service-Container">
        <p className="dashboard-title">Select Type of Service</p>
        <div className="row">
          {spinLoad ? (
            <>
              <div className="loader-container">
                <SpinnerCircular size="80px" color="#FF9517" />
              </div>
            </>
          ) : (
            allServiceData?.map((item, index) => {
              return (
                <ServicesCard
                  item={item}
                  index={index}
                  serviceId={serviceId}
                  selectHandler={selectHandler}
                />
              );
            })
          )}
        </div>
        <div className="btn-container">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => ServiceTypeHandler()}
            disabled={!serviceId}
          >
            Continue
          </button>
        </div>
        <div className="pagination-container mt-5">
          <ReactPaginate
            // renderOnZeroPageCount={null}
            previousLabel="< previous"
            nextLabel="next >"
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div>
    </>
  );
}

export default ServiceType;
