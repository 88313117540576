import React from "react";
import { Outlet, useNavigate } from "react-router-dom";

function ContractorCard(props) {
  const { item, index, cardDetailView } = props;
  const Navigate = useNavigate();
  // window.location.href = "/selectedcontractoropportunity";
  return (
    <>
      <div className="col-md-3 col-sm-12 mt-4" key={item?.id}>
        <div className="contractor-Card">
          <p className="cont-card-title">{item?.title}</p>
          <p className="cont-card-detail">{item?.description}</p>
          <p
            // to="/selectedcontractoropportunity"
            className="viewDetail"
            onClick={(e) => {
              Navigate("/selectedcontractoropportunity", { state: item });
              // cardDetailView(e);
            }}
          >
            View Details
          </p>
        </div>
      </div>
      <Outlet />
    </>
  );
}

export default ContractorCard;
