import React from "react";
import { ImageUrl } from "../../../network/ApiUrl";

function ServicesCard(props) {
  const { item, index } = props;
  return (
    <>
      {index >= 6 ? null : (
        <div className="col-md-4 d-flex align-items-stretch mb-2" key="index">
          <div className="step deliver-card">
            <img
              src={`${ImageUrl}/${item?.icon}`}
              // src={item?.icon}
              alt=""
              className="img-fluid text-center mb-3 d-block ml-auto mr-auto"
            />
            <h5 className="text-black text-center mb-4">{item?.name}</h5>
            <p className="text-black text-center pl-3 pr-3 font-weight-light"
              style={{    fontSize: "14px"}}
            >
              {item?.description}
            </p>
            <p className="text-center mt-1">${item?.price}</p>
          </div>
        </div>
      )}
    </>
  );
}

export default ServicesCard;

{
  /* <div className="col-md-4 d-flex align-items-stretch mb-2" key="index">
<div className="step deliver-card ">
  <img
    src={item?.img}
    alt=""
    className="img-fluid text-center mb-3 d-block ml-auto mr-auto"
  />
  <h5 className="text-white text-center mb-4">{item?.title}</h5>
  <p className="text-white text-center pl-3 pr-3 font-weight-light">
    {item?.description}
  </p>
</div>
</div> */
}
