import React from "react";
import OrderTracking from "../pages/Dashboard/OrderTracking/OrderTracking";
import PlaceOrder from "../pages/Dashboard/PlaceOrder/PlaceOrder";
import ReportIssues from "../pages/Dashboard/ReportIssues/ReportIssues";
import RiderTracking from "../pages/Dashboard/RiderTracking/RiderTracking";
import ServiceType from "../pages/Dashboard/ServiceType/ServiceType";
import ProtectedRoute from "./ProtectedRoutes";
// import { Redirect } from 'react-router';
import {
  Redirect,
  Route,
  Navigate,
  Outlet,
  Routes,
  BrowserRouter,
} from "react-router-dom";
import PaymentMethod from "../pages/Dashboard/PaymentMethod/PaymentMethod";
import DriverRating from "../pages/Dashboard/DriverRating/DriverRating";
import EmploymentForm from "../pages/Dashboard/Employment/EmploymentForm";
import OrderInformation from "../pages/Dashboard/OrderInfo/OrderInformation";
import GetQuote from "../pages/Dashboard/GetQuote/GetQuote";
import ProtectedRoutes from "./ProtectedRoutes";
import SelectedEmploymentOpportunity from "../pages/Dashboard/Employment/SelectedEmploymentOpportunity";
import EmploymentOpportunity from "../pages/Dashboard/Employment/EmploymentOpportunity";
import ContractorOpportunity from "../pages/Dashboard/Contractor/ContractorOpportunity";
import Invoice from "../pages/Dashboard/Invoice/Invoice";
import Chat from "../pages/Dashboard/Chat/Chat";
import ContractorForm from "../pages/Dashboard/Contractor/ContractorForm";
import Profile from "../pages/Dashboard/Profile/Profile";
import Layout from "../pages/LandingPage/Layout";
import EditProfile from "../pages/Dashboard/Profile/EditProfile";
import Notification from "../pages/Dashboard/Notification/Notification";
import Login from "../pages/AuthViews/Login";
import SignUp from "../pages/AuthViews/SignUp";
import ForgetPassword from "../pages/AuthViews/ForgetPassword";
import OtpVerification from "../pages/AuthViews/OtpVerification";
import CreatePassword from "../pages/AuthViews/CreatePassword";
import AllJobs from "../pages/Dashboard/OrderTracking/AllJobs";

function DashboardRoutes() {
  return (
    <>
      <Routes>
        <Route path="dashboard" element={<ProtectedRoutes />}>
          <Route path="services" element={<ServiceType />} />
          <Route path="placeorder" element={<PlaceOrder />} />
          <Route path="ordertracking" element={<OrderTracking />} />

          <Route path="ridertracking" element={<RiderTracking />} />
          <Route path="reportissues" element={<ReportIssues />} />
          <Route path="paymentmethod" element={<PaymentMethod />} />
          <Route path="driverrating" element={<DriverRating />} />
          {/* <Route
            path="employmentopportunity"
            element={<EmploymentOpportunity />}
          />
          <Route
            path="selectedemploymentopportunity"
            element={<SelectedEmploymentOpportunity />}
          /> */}
          {/* <Route path="employmentform" element={<EmploymentForm />} /> */}
          <Route path="orderinformation" element={<OrderInformation />} />
          <Route path="getquote" element={<GetQuote />} />
          {/* <Route
            path="contractoropportunity"
            element={<ContractorOpportunity />}
          />
          <Route path="contractorform" element={<ContractorForm />} /> */}
          <Route path="invoice" element={<Invoice />} />
          <Route path="chat" element={<Chat />} />
          <Route path="profile" element={<Profile />} />
          <Route path="editprofile" element={<EditProfile />} />
          <Route path="notification" element={<Notification />} />
          <Route path="alljobs" element={<AllJobs />} />
          <Route path="*" element={<Navigate to="/dashboard/services" />} />
        </Route>
      </Routes>
      {/* <Redirect to="/" /> */}
    </>
  );
}

export default DashboardRoutes;
