import React, { useEffect, useState } from "react";
import NotificationCard from "../../../components/DashboardComp/Notification/NotificationCard";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { NotificationApi } from "../../../redux/actions/AuthAction";
import { GetNotification, UpdateNotification } from "../../../network/Network";
import { useLocation } from "react-router-dom";

function Notification(props) {
  const dispatch = useDispatch();
  const Notifications = useSelector((state) => state.AuthReducer.notifications);
  const Token = useSelector((state) => state.AuthReducer.token);
  const [notification, setNotification] = useState([]);
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  let limit = 10;

  const handlePageClick = async (data) => {
    console.log(data.selected);
    setCurrentPage(data?.selected + 1);
  };

  // //  == Get Notification ==
  // useEffect(() => {
  //   GetNotification(currentPage, Token)
  //     .then((res) => {
  //       console.log(res);
  //       const total = res?.data?.response?.data?.total;
  //       setPageCount(Math.ceil(total / limit));
  //       setNotification(res?.data?.response?.data?.data);
  //       dispatch(NotificationApi(res?.data?.response?.data?.data));
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  // Update Notification
  useEffect(() => {
    UpdateNotification(currentPage, Token)
      .then((res) => {
        console.log(res);
        const total = res?.data?.response?.data?.total;
        setPageCount(Math.ceil(total / limit));
        setNotification(res?.data?.response?.data?.data);
        dispatch(NotificationApi(res?.data?.response?.data?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [currentPage]);

  console.log(Notifications);
  console.log(Notifications?.description);
  return (
    <>
      <div className="notification-Container">
        <p className="dashboard-title">Notification</p>
        <div className="serviceCard">
          {notification.length >= 0 ? (
            Notifications?.reverse().map((item, index) => {
              return (
                <div className="notificationCard">
                  <NotificationCard item={item} index={index} />
                </div>
              );
            })
          ) : (
            <p>No Record Found</p>
          )}

          <div className="pagination-container">
            <ReactPaginate
              // renderOnZeroPageCount={null}
              previousLabel="< previous"
              nextLabel="next >"
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Notification;
