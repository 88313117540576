import React, { useState, useEffect } from "react";
import OrderDeliveryForm from "../../../components/DashboardComp/PlaceOrder/OrderDeliveryForm";
import ServiceTypeForm from "../../../components/DashboardComp/PlaceOrder/ServiceTypeForm";
import { Outlet, useNavigate, useLocation } from "react-router";
import { GetAllAddress, POSTJOBS } from "../../../network/Network";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { AddressData } from "../../../redux/actions/AuthAction";

function PlaceOrder(props) {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const Location = useLocation();
  console.log(Location);
  const Token = useSelector((state) => state.AuthReducer.token);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [zipCode, setZipCode] = useState("");

  const [serviceType, setServiceType] = useState("");
  const [items, setItems] = useState("");
  const [code, setCode] = useState("");

  const [pickUpAddress, setPickUpAddress] = useState("");
  const [dropOffAddress, setDropOffAddress] = useState("");
  const [originContactPerson, setOriginContactPerson] = useState("");
  const [destinationContactPerson, setDestinationContactPerson] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [originAddress, setOriginAddress] = useState("");
  const [cityOrigin, setCityOrigin] = useState("");
  const [zipOrigin, setZipOrigin] = useState("");
  const [countryOrigin, setCountryOrigin] = useState("");
  const [stateOrigin, setStateOrigin] = useState("");

  const [loading, setLoading] = useState(false);

  const [allAddress, setAllAddress] = useState([]);

  const submitDetails = (e) => {
    e.preventDefault();
    setLoading(true);
    let ServiceID = Location?.state?.id;
    let ServiceType = Location?.state?.data?.name;
    if (
      !ServiceID ||
      !firstName ||
      !lastName ||
      !city ||
      !deliveryAddress ||
      !zipCode ||
      !phoneNumber ||
      !items ||
      !ServiceType ||
      !code ||
      !originContactPerson ||
      !destinationContactPerson ||
      !pickUpAddress ||
      !dropOffAddress ||
      !originAddress ||
      !cityOrigin ||
      !stateOrigin ||
      !zipOrigin ||
      !countryOrigin
    ) {
      toast.error("Please Enter All Field");
      setLoading(false);
      return;
    }
    if (items <= 0) {
      toast.error("Please Enter Positive Number");
      setLoading(false);
      return;
    }
    if (pickUpAddress === dropOffAddress) {
      toast.error("Pickup And dropOff Address are Same");
      setLoading(false);
      return;
    }

    let data = {
      service_id: Location?.state?.id,
      delivery_f_name: firstName,
      delivery_l_name: lastName,
      delivery_city: city,
      delivery_address: deliveryAddress,
      delivery_zip: zipCode,
      delivery_phone_no: phoneNumber,
      item: items,
      serviceType: Location?.state?.data?.name,
      code: code,
      contact_name_origin: originContactPerson,
      contact_name_destination: destinationContactPerson,
      origin_address_id: pickUpAddress,
      destination_address_id: dropOffAddress,
      address_origin: originAddress,
      country_origin: countryOrigin,
      city_origin: cityOrigin,
      state_origin: stateOrigin,
      zip_origin: zipOrigin,
      country_origin: countryOrigin,
    };
    console.log(data);
    POSTJOBS(data, Token)
      .then((res) => {
        console.log(res);
        toast.success(res?.data?.message);
        if (res?.data?.statusCode == 200) {
          Navigate("/dashboard/orderinformation", {
            state: res?.data?.response?.data,
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Please Enter All Field");
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    GetAllAddress(Token)
      .then((res) => {
        console.log(res);
        dispatch(AddressData(res?.data?.response?.data?.data));
        setAllAddress(res?.data?.response?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div className="placeOrder-Container">
        <p className="dashboard-title">Place Your Order</p>

        <div className="row">
          <div className="col-md-12">
            <OrderDeliveryForm
              firstName={firstName}
              setFirstName={setFirstName}
              lastName={lastName}
              setLastName={setLastName}
              city={city}
              setCity={setCity}
              street={street}
              setStreet={setStreet}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              zipCode={zipCode}
              setZipCode={setZipCode}
              deliveryAddress={deliveryAddress}
              setDeliveryAddress={setDeliveryAddress}
              originAddress={originAddress}
              setOriginAddress={setOriginAddress}
              cityOrigin={cityOrigin}
              setCityOrigin={setCityOrigin}
              zipOrigin={zipOrigin}
              setZipOrigin={setZipOrigin}
              countryOrigin={countryOrigin}
              setCountryOrigin={setCountryOrigin}
              stateOrigin={stateOrigin}
              setStateOrigin={setStateOrigin}
            />
          </div>
          <div className="col-md-12 mt-5">
            <ServiceTypeForm
              serviceType={serviceType}
              setServiceType={setServiceType}
              items={items}
              setItems={setItems}
              code={code}
              setCode={setCode}
              pickUpAddress={pickUpAddress}
              setPickUpAddress={setPickUpAddress}
              dropOffAddress={dropOffAddress}
              setDropOffAddress={setDropOffAddress}
              setOriginContactPerson={setOriginContactPerson}
              originContactPerson={originContactPerson}
              setDestinationContactPerson={setDestinationContactPerson}
              destinationContactPerson={destinationContactPerson}
              allAddress={allAddress}
            />
          </div>
        </div>
        <div className="btn-container">
          <button
            type="button"
            className="btn btn-primary"
            onClick={(e) => {
              submitDetails(e);
            }}
            disabled={loading}
          >
            {loading ? "Loading.." : "Continue"}
          </button>
        </div>
      </div>
    </>
  );
}

export default PlaceOrder;
