import React, { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ReportIssue } from "../../../network/Network";

function ReportIssues() {
  const Token = useSelector((state) => state.AuthReducer.token);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState("");

  // Report Issues
  const ReportIssues = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!subject || !message) {
      toast.error("Please Enter All Fields");
      setLoading(false);
      return;
    }
    let data = {
      subject: subject,
      message: message,
    };
    console.log(data);
    ReportIssue(data, Token)
      .then((res) => {
        setLoading(false);
        console.log(res);
        toast.success("Successfully send");
        setMessage("");
        setSubject("");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setMessage("");
        setSubject("");
      });
  };

  return (
    <>
      <div className="Report-Container">
        <p className="dashboard-title">Report an Issue</p>
        <div className="serviceCard">
          <form>
            <div className="form-group ">
              <div className="report-subj">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Subject"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  required
                />
              </div>
              <div className="report-msg">
                <textarea
                  type="text"
                  className="form-control"
                  placeholder="Write Your Message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                />
              </div>
            </div>
          </form>
        </div>
        <div className="btn-container">
          <button
            type="button"
            className="btn btn-primary"
            disabled={loading}
            onClick={(e) => ReportIssues(e)}
          >
            {loading ? "Loading.." : "Continue"}
          </button>
        </div>
      </div>
    </>
  );
}

export default ReportIssues;
