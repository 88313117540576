import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { Outlet, useNavigate, useLocation } from "react-router";
import Navbar from "../../../components/LandingpageComp/Navbar/Navbar";
import moment from "moment";

function SelectedEmploymentOpportunity() {
  const Navigate = useNavigate();
  const location = useLocation();
  const jobData = location?.state;
  console.log(location);
  return (
    <>
      <Navbar page={"employment"} />
      <div className="Employment-Container mt-5 mb-5">
        <p className="dashboard-title mt-5">Employment Opportunity</p>
        <div className="serviceCard">
          <p className="EmploymentTitle">{jobData?.title}</p>
          <hr />
          <div className="job-header">
            <div className="feature-tag">Featured</div>
            <div className="flexBox-style">
              <FontAwesomeIcon icon={faStar} className="starIcon" />
              <div>
                <p className="address">{jobData?.country?.name}</p>
                <p className="address">
                  {moment(jobData?.updated_at).format("MMMM Do YYYY, h:mm a")}
                </p>
              </div>
            </div>
          </div>
          <div>
            <p className="jobDesc-title">Job Description</p>
            <p className="job-para">{jobData?.description}</p>
            {/* <p className="job-para mt-5">
            {jobData?.description}
            </p> */}
          </div>
        </div>
        <div className="btn-container">
          <button
            type="button"
            className="btn btn-primary"
            style={{ width: "unset" }}
            onClick={() => {
              Navigate("/employmentform", { state: jobData });
            }}
          >
            Apply Now
          </button>
        </div>
      </div>
    </>
  );
}

export default SelectedEmploymentOpportunity;
