import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import GoogleMap from "../../../components/DashboardComp/GoogleMap/GoogleMap";
import { Inputplace } from "../../../components/DashboardComp/PlaceAutoComplete/PlaceAutoComplete";

function RiderTracking() {
  const Navigate = useNavigate();
  const location = useLocation();
  const trackingData = location.state;
  console.log("lat check", location.state);
  return (
    <>
      <div className="RiderTracking-Container">
        <p className="dashboard-title">Track Rider</p>
        <div className="serviceCard">
          <GoogleMap trackingData={trackingData} />
        </div>
        <div className="btn-container">
          <button
            type="button"
            className="btn btn-primary"
            style={{ width: "unset" }}
            onClick={() => Navigate("/dashboard/alljobs")}
          >
            Back To Order Tracking
          </button>
        </div>
        {/* <Inputplace /> */}
      </div>
    </>
  );
}

export default RiderTracking;
