import React from "react";
import { Link } from "react-router-dom";

function DescriptionSection() {
  return (
    <>
      <section className="description">
        <div className="container">
          <div className="row p-5">
            <div className="col-md-6 mb-5">
              <h3 className="text-black font-weight-bold">
              Why Only Daja?
                {/* What makes us better than everyone else */}
              </h3>
              <p className="text-black font-weight-light">
                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam. adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                veniam. incididunt ut labore et dolore magna aliqua. Ut enim
                addimo minim veniam. */}
                As one of the leading transportation service providers, Daja assists businesses to grow by efficiently handling their logistics and shipping operations. Over here, we strongly look for opportunities to earn our client’s trust by providing extraordinary countrywide delivery solutions while also passionately valuing our communities, partners, and co-workers.
              </p>
              <Link
                to="/contractoropportunity"
                className="login-btn text-light"
              >
                GET STARTED
              </Link>
            </div>
            <div className="col-md-6 text-center">
              {/* <video width="370" height="255" poster="img/truck-img.png" controls>
                                <source src="video.mp4" type="video/mp4">
                                <source src="video.ogg" type="video/ogg">
                                <source src="video.webm" type="video/webm">
                                <object data="video.mp4" width="470" height="255">
                                    <embed src="video.swf" width="470" height="255">
                                </object>
                            </video> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DescriptionSection;
