import React from "react";
import Footer from "../../components/LandingpageComp/Footer/Footer";
import Header from "../../components/LandingpageComp/Header/Header";
import Navbar from "../../components/LandingpageComp/Navbar/Navbar";
import EmploymentOpportunity from "../Dashboard/Employment/EmploymentOpportunity";
import Aboutus from "./AboutUs/Aboutus";
import DescriptionSection from "./DescriptionSection";
import WhyDaJa from "./Features/WhyDaJa";
import ServicesSection from "./ServicesSection";

function Layout() {
  return (
    <>
      <Navbar page={"LandingPage"} />
      <Header />
      <ServicesSection />
      <Aboutus />
      <WhyDaJa />
      <DescriptionSection />
      {/* <EmploymentOpportunity /> */}
      <Footer />
    </>
  );
}

export default Layout;
