import React, { useState } from "react";
import Navbar from "../../../components/LandingpageComp/Navbar/Navbar";
import { PostResume } from "../../../network/Network";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

function EmploymentForm() {
  const Token = useSelector((state) => state.AuthReducer.token);
  const location = useLocation();
  const allJob = location?.state;
  console.log(location?.state);
  const [mediaFile, setMediaFile] = useState();
  const [loading, setLoading] = useState(false);

  const handleImageUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e?.target?.files[0];
    reader.onloadend = () => {
      setMediaFile(file);
      console.log("media", file);
    };
    reader?.readAsDataURL(file);
  };

  // == Search Job ==
  const UploadResume = async (e) => {
    setLoading(true);
    e.preventDefault();
    console.log(mediaFile);
    let data = await new FormData();
    data.append("employment_id", allJob?.id);
    data.append("attachment", mediaFile);
    console.log(data);
    PostResume(data, Token)
      .then((res) => {
        console.log(res);
        toast.success(res?.data?.message);
        // dispatch(AllServices(res?.data?.response?.data));
        setLoading(false);
        setMediaFile("");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.data?.message);
        setLoading(false);
      });
  };
  return (
    <>
      <Navbar page={"employment"} />
      <div className="EmploymentForm-Container mt-5 mb-5">
        <p className="dashboard-title mt-5">Employment From</p>
        <div className="serviceCard">
          <p className="subTitle">Upload Your Resume Maximum 20 MB File</p>
          <div className="uploadContainer">
            <button
              type="button"
              className="btn btn-primary"
              disabled={mediaFile}
            >
              Upload Resume
            </button>
            <input
              type="file"
              accept="application/pdf,
              application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              onChange={handleImageUpload}
              multiple="false"
            />
          </div>
        </div>
        <div className="btn-container">
          <button
            type="button"
            className="btn btn-primary"
            onClick={(e) => UploadResume(e)}
            disabled={loading}
          >
            {loading ? "Loading.." : "Submit"}
          </button>
        </div>
      </div>
    </>
  );
}

export default EmploymentForm;
