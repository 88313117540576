import React from "react";
import { Link } from "react-router-dom";
import { topBarIcon, playBtn } from "../../../constant";

function Header() {
  return (
    <>
      {/* <!-- ======= Hero Section ======= --> */}
      <section className="hero-section" id="hero">
        {/* <!-- ======= Top Bar Section ======= --> */}
        <div className="container-fluid top-bar">
          <div id="left">
            <img src={topBarIcon} alt="" className="img-fluid p-1 w-80" />
          </div>
          <div id="center">
            <p className="font-weight-bold">Intrested in Joining our Team?</p>
          </div>
          <div className="right">
            <Link to="/contractoropportunity" className="apply-now-btn">
              APPLY NOW
            </Link>
          </div>
        </div>
        {/* <!-- ======= End Top Bar Section ======= --> */}
        <div className="wave">
          <svg
            width="100%"
            height="355px"
            viewBox="0 0 1920 355"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            // xmlns:xlink="http://www.w3.org/1999/xlink"
          >
            <g
              id="Page-1"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                id="Apple-TV"
                transform="translate(0.000000, -402.000000)"
                fill="#FFFFFF"
              >
                <path
                  d="M0,439.134243 C175.04074,464.89273 327.944386,477.771974 458.710937,477.771974 C654.860765,477.771974 870.645295,442.632362 1205.9828,410.192501 C1429.54114,388.565926 1667.54687,411.092417 1920,477.771974 L1920,757 L1017.15166,757 L0,757 L0,439.134243 Z"
                  id="Path"
                ></path>
              </g>
            </g>
          </svg>
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 hero-text-image">
              <div className="row slider-row">
                <div className="col-lg-6 text-lg-start slider-text">
                  <h2 className="text-light font-weight-bold">
                    {/* The Fastest and safest transport that help you to move your
                    deliveries */}
                    Explore The World of Daja’s Brisk Delivery Solutions
                  </h2>
                  <p className="mb-4 mt-3 text-light">
                    {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam. */}
                    What if your employees could spend less time quarrelling over carriers, slogging through the paperwork or following up on shipments? What would your company’s core look like if you reinvested all those hours— and money — into expanding your business instead?
                  </p>
                  <p className="mb-4 mt-3 text-light">With a delivery solutions expert like Daja on your team, take the lead!</p>
                </div>
                <div className="col-md-12 play_btn d-flex ml-3">
                  <Link
                    to="/contractoropportunity"
                    className="login-btn text-light"
                  >
                    GET STARTED
                  </Link>
                  <img src={playBtn} alt="" className="ml-4" />
                  <a className="ml-3 mt-2 text-light">Watch Intro</a>
                </div>
                <div className="col-md-12 text-center track-package">
                  {/* <p className="text-light font-weight-bold text-center package-text">
                    Track your Package by entering your tracking Id
                  </p> */}
                  {/* <form>
                    <div className="track-package-section">
                      <input
                        type="text"
                        className="package-input"
                        placeholder="Enter Your Tracking ID"
                      />
                      <button className="package-btn">TRACK</button>
                    </div>
                  </form>
                  <p className="text-center align-items-center mt-3">
                    <a className="text-center align-items-center text-light">
                      Need Help?
                    </a>
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Hero -->   */}
    </>
  );
}

export default Header;
