import React from "react";
import { Link } from "react-router-dom";
import FeaturesCard from "../../../components/LandingpageComp/Features/FeaturesCard";
import { whyIcon1, whyIcon2, whyIcon3, whyIcon4 } from "../../../constant";

function WhyDaJa() {
  const featuresData = [
    {
      id: 1,
      img: whyIcon1,
      title: "Deliver Anywhere",
      title: "Quick sign-up",
    },
    {
      id: 2,
      img: whyIcon2,
      // title: "Available Worldwide",
      title: "Deliver nationwide",
    },
    {
      id: 3,
      img: whyIcon3,
      // title: "Deliver Any size",
      title: "Bulk management",
    },
    {
      id: 4,
      img: whyIcon4,
      // title: "24 hours support",
      title: "24/7 Assistance",
    },
  ];
  return (
    <>
      <section className="why-daja-section">
        <div className="container">
          <div className="row justify-content-center text-center mb-5 why-daja-heading">
            <div className="col-md-5">
              <h4 className="section-heading text-light font-weight-bold">
                Why Daja Transport?
              </h4>
            </div>
            <div className="col-md-12">
              <p className="text-center text-light justify-content-center why-daja-content w-50 d-block ml-auto mr-auto">
                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam */}
                Need a swift delivery? That’s where we thrive! Every industry be it IT or Pharmaceutical, Medical or Financial, trust us for the finest packaging and express shipping of their goods!
              </p>
            </div>
          </div>
          <div className="row">
            {featuresData?.map((item, index) => {
              return <FeaturesCard item={item} index={index} />;
            })}
            <div className="col-md-12 mt-3 text-center mt-5">
              <Link
                to="/contractoropportunity"
                className="login-btn text-light"
              >
                GET STARTED
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default WhyDaJa;
