import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  SET_PASSWORD_REQUEST,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_ERROR,
  // GET_ME_SUCCESS,
  // LOGOUT_REQUEST,
  // LOGOUT_SUCCESS,
  // LOGOUT_ERROR,
  LOGOUT,
  USER_TOKEN,
  ALL_STATES_DATA,
  ALL_COUNTRY,
  JOBS,
  EDIT_PROFILE,
  All_PAYMENT_METHOD,
  ADDRESS,
  All_JOB_DATA,
  AUTO_ADDRESS,
  SERVICE_DATA,
  NOTIFICATION,
  ALL_CHAT,
  SERVICES,
  SET_NEW_MESSAGE,
  Term_Condition,
} from "../Types";
import {
  forgotPasswordOtp,
  forgotPasswordApi,
  postSignIn,
  postSignUp,
  setNewPasswordApi,
  forgotPasswordEmailApi,
  PostChats,
} from "../../network/Network";
import { toast } from "react-toastify";
import { setError } from "../actions/AlertAction";
import { responses, responsesLogin } from "../../constant/ConstantFunction";
import { Navigate, useNavigate } from "react-router-dom";

export const login =
  (data, cb = () => {}) =>
  (dispatch) =>
    new Promise(async (resolve, reject) => {
      dispatch({ type: LOGIN_REQUEST });
      postSignIn(data)
        .then((res) => {
          // console.log("Login",{response : { ...res}});
          //  if(res.data.){
          console.log(res);
          // toast.success("Successfully Login");
          dispatch({
            type: LOGIN_SUCCESS,
            payload: res?.data?.response?.data?.user,
          });
          dispatch({
            type: USER_TOKEN,
            payload: res?.data?.response?.data?.token,
          });
          // }

          dispatch(setError(responsesLogin(res)));

          return resolve(true);
        })
        .catch((err) => {
          console.log("Login", err.response);
          if (err?.response?.data?.message == "You Must Verify Your Email") {
            toast.info("You Must Verify Your Email");
          } else {
            toast.error("Please Enter Correct Email or Password");
          }

          // dispatch(setError(responses(err)));
          // toast.error(err?.response?.data?.payload?.message);
          dispatch({ type: LOGIN_ERROR });
          return reject(false);
        });
    });

export const signUp = (data, cb) => (dispatch) => {
  new Promise(async (resolve, reject) => {
    dispatch({ type: SIGNUP_REQUEST });
    postSignUp(data)
      .then((res) => {
        console.log(res);
        dispatch(setError(responses(res)));
        dispatch({ type: SIGNUP_SUCCESS, payload: res?.data?.data });
        toast.success(res?.data?.message);
        dispatch(setError("success", "Successfully Signup!!!"));
        return resolve(true);
      })
      .catch((err) => {
        console.log("Error", err.response);
        dispatch(setError(responses(err)));
        toast.error(err?.response?.data?.payload?.message);
        return reject(false);
      })
      .finally(() => {
        dispatch({ type: SIGNUP_ERROR });
      });
  });
};

export const forgotPassword = (data) => (dispatch) => {
  new Promise(async (resolve, reject) => {
    dispatch({ type: FORGOT_PASSWORD_REQUEST });
    forgotPasswordApi(data)
      .then((res) => {
        dispatch(setError(responses(res)));
        dispatch({ type: FORGOT_PASSWORD_SUCCESS });
        console.log(res);
        toast.success(res?.data?.message);
        return resolve(true);
      })
      .catch((err) => {
        dispatch(setError(responses(err)));
        toast.error(err?.response?.data?.payload?.message);
        dispatch({ type: FORGOT_PASSWORD_ERROR });
        return reject(false);
      });
  });
};

export const forgotPasswordEmail = (data) => (dispatch) => {
  new Promise(async (resolve, reject) => {
    dispatch({ type: FORGOT_PASSWORD_REQUEST });
    forgotPasswordEmailApi(data)
      .then((res) => {
        dispatch(setError(responses(res)));
        dispatch({ type: FORGOT_PASSWORD_SUCCESS });
        toast.success("Email has been sent to your email address");
        console.log(res);
        return resolve(true);
      })
      .catch((err) => {
        dispatch(setError(responses(err)));
        toast.error(err?.response?.data?.payload?.message);
        dispatch({ type: FORGOT_PASSWORD_ERROR });
        return reject(false);
      });
  });
};

export const forgotPasswordOtpVerify =
  (data, param, Navigate) => (dispatch) => {
    new Promise(async (resolve, reject) => {
      dispatch({ type: FORGOT_PASSWORD_REQUEST });
      forgotPasswordOtp(data)
        .then((res) => {
          dispatch(setError(responses(res)));
          dispatch({ type: FORGOT_PASSWORD_SUCCESS });
          toast.info(res?.data?.message);
          console.log(res);
          if (res?.data?.statusCode == 200) {
            Navigate("/createpassword", { state: param });
          }

          // toast.success("Email has been sent to your email address");
          return resolve(true);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.payload?.message);
          dispatch(setError(responses(err)));
          dispatch({ type: FORGOT_PASSWORD_ERROR });
          return reject(false);
        });
    });
  };

export const setPassword = (password, resetToken, cb) => (dispatch) => {
  dispatch({ type: SET_PASSWORD_REQUEST });
  setNewPasswordApi({ password }, resetToken)
    .then((res) => {
      toast.success("Your password has been reset");
      dispatch({ type: SET_PASSWORD_SUCCESS });
      // cb();
    })
    .catch((err) => {
      toast.error(err?.response?.data?.payload?.message);
      dispatch({ type: SET_PASSWORD_ERROR });
    });
};

export const logout = (payload) => {
  return {
    type: LOGOUT,
    payload,
  };
};

export const EditProfileData = (payload) => {
  return {
    type: EDIT_PROFILE,
    payload,
  };
};

export const AllServicesData = (payload) => {
  return {
    type: SERVICES,
    payload,
  };
};

export const GetJobs = (payload) => {
  return {
    type: JOBS,
    payload,
  };
};

export const AllPaymentMethod = (payload) => {
  return {
    type: All_PAYMENT_METHOD,
    payload,
  };
};

export const AddressData = (payload) => {
  return {
    type: ADDRESS,
    payload,
  };
};

export const AllJobData = (payload) => {
  return {
    type: All_JOB_DATA,
    payload,
  };
};

export const AllStates = (payload) => {
  return {
    type: ALL_STATES_DATA,
    payload,
  };
};

export const NotificationApi = (payload) => {
  return {
    type: NOTIFICATION,
    payload,
  };
};

export const setNewMessageData = (payload) => {
  return {
    type: SET_NEW_MESSAGE,
    payload,
  };
};

export const AllChatData = (payload) => {
  return {
    type: ALL_CHAT,
    payload,
  };
};

export const AllServices = (payload) => {
  return {
    type: SERVICES,
    payload,
  };
};

export const AutoAddress = (payload) => {
  return {
    type: AUTO_ADDRESS,
    payload,
  };
};

export const AllCountry = (payload) => {
  return {
    type: ALL_COUNTRY,
    payload,
  };
};

export const TermConditionStore = (payload) => {
  return {
    type: Term_Condition,
    payload,
  };
};
