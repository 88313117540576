import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function OrderInformation() {
  const Navigate = useNavigate();
  const Location = useLocation();
  console.log(Location?.state);
  const orderData = Location?.state;
  const Services = useSelector((state) => state.AuthReducer.services);
  const serviceData = Services?.find((x) => x.id === orderData?.service_id);
  console.log(serviceData);
  return (
    <>
      <div className="OrderInformation-Container">
        <p className="dashboard-title">Your OrderInformation</p>
        <div className="serviceCard">
          <p className="subtitle">Thank You For Your Order</p>
          <p className="orderNum">
            Your Order Tracking Number:{""} {orderData?.tracking_code}
          </p>
        </div>
        <div className="serviceCard mt-5">
          <div className="row">
            <div className="col-md-4">
              <div className="flexBox-start">
                <p className="detail-title">First Name:</p>
                {""}
                <p className="detail-title">
                  {orderData?.customer?.first_name}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="flexBox-start">
                <p className="detail-title">Last Name:</p>
                {""}
                <p className="detail-title">{orderData?.customer?.last_name}</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="flexBox-start">
                <p className="detail-title">Contact no:</p>
                {""}
                <p className="detail-title">{orderData?.customer?.phone_no}</p>
              </div>
            </div>
            <div className="col-md-12 mt-4">
              <div className="Address-Container">
                <p className="detail-title">Address:</p>
                {""}
                <p className="address">{orderData?.customer?.address}</p>
              </div>
            </div>
            <div className="col-md-6 mt-4">
              <div className="flexBox-style">
                <p className="pickupTitle">Pick Up Address:</p>
                {""}
                <p className="pickup">{orderData?.destination_address_id}</p>
              </div>
            </div>
            <div className="col-md-6 mt-4">
              <div className="flexBox-style">
                <p className="pickupTitle">Drop Off Address:</p>
                {""}
                <p className="pickup">{orderData?.delivery_address}</p>
              </div>
            </div>
            <div>
              <p className="Amount">Amount : ${orderData?.amount}</p>
            </div>
          </div>
        </div>
        <div className="serviceCard mt-5">
          <p className="serviceType">Service Type</p>
          <hr />
          <div className="row">
            <div className="col-md-12 mt-4">
              <div className="flexBox-start">
                <p className="pickupTitle">Service Type :</p>
                <p className="pickup">{serviceData?.name}</p>
              </div>
            </div>
            <div className="col-md-12 mt-4">
              <div className="flexBox-start">
                <p className="pickupTitle">Item Details :</p>
                <p className="pickup">{serviceData?.description}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="btn-container">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => Navigate("/dashboard/services")}
          >
            Continue
          </button>
        </div>
      </div>
    </>
  );
}

export default OrderInformation;
