import React, { useEffect, useState } from "react";
import InvoiceTable from "../../../components/DashboardComp/Table/InvoiceTable";
import { GetInvoice } from "../../../network/Network";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import { SpinnerCircular } from "spinners-react";

function Invoice(props) {
  const Token = useSelector((state) => state.AuthReducer.token);
  const [invoiceData, setInvoiceData] = useState([]);
  const [spinLoad, setSpinLoad] = useState(true);
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  let limit = 10;

  const handlePageClick = async (data) => {
    console.log(data.selected);
    setCurrentPage(data?.selected + 1);
  };

  useEffect(() => {
    setSpinLoad(true);
    GetInvoice(currentPage, Token)
      .then((res) => {
        setInvoiceData(res?.data?.response?.data?.data);
        const total = res?.data?.response?.data?.total;
        setPageCount(Math.ceil(total / limit));
        setSpinLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinLoad(false);
      });
  }, [currentPage]);

  return (
    <>
      <div className="Invoice-Container">
        <p className="dashboard-title">Invoice</p>
        <div className="serviceCard">
          {spinLoad ? (
            <>
              <div className="loader-container">
                <SpinnerCircular size="80px" color="#FF9517" />
              </div>
            </>
          ) : (
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col" className="tableHead">
                    Track No
                  </th>
                  <th scope="col" className="tableHead">
                    Service Type
                  </th>
                  <th scope="col" className="tableHead">
                    Items
                  </th>
                  <th scope="col" className="tableHead">
                    Amount
                  </th>
                  <th scope="col" className="tableHead">
                    Pick up Details
                  </th>
                  <th scope="col" className="tableHead">
                    Drop Off Location
                  </th>
                  <th scope="col" className="tableHead">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {invoiceData?.map((item, index) => {
                  return <InvoiceTable item={item} index={index} />;
                })}
              </tbody>
            </table>
          )}

          <div className="pagination-container">
            <ReactPaginate
              // renderOnZeroPageCount={null}
              previousLabel="< previous"
              nextLabel="next >"
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <button type="button" className="btn btn-primary ">
            View
          </button>
        </div>
      </div>
    </>
  );
}

export default Invoice;
