import React, { useState } from "react";
import { test, blueLogo } from "../../../constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faCamera } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { PostChangePassword, UpdateProfile } from "../../../network/Network";
import { EditProfileData } from "../../../redux/actions/AuthAction";
import { ImageUrl } from "../../../network/ApiUrl";
import { toast } from "react-toastify";
import ChangePasswordModal from "../../../components/DashboardComp/Modal/ChangePasswordModal";
import { Navigate, useNavigate } from "react-router-dom";
import { ramdomImage } from "../../../constant/ConstantFunction";

function EditProfile() {
  const Navigate = useNavigate();
  let dispatch = useDispatch();
  const token = useSelector((state) => state.AuthReducer.token);
  const userData = useSelector((state) => state.AuthReducer.users);

  const [firstName, setFirstName] = useState(userData?.first_name);
  const [lastName, setLastName] = useState(userData?.last_name);
  const [email, setEmail] = useState(userData?.email);
  const [phoneNumber, setPhoneNumber] = useState(userData?.phone_no);
  const [address, setAddress] = useState(userData?.address);
  const [gender, setGender] = useState(userData?.gender);
  const [bio, setBio] = useState(userData?.bio);

  const [newFile, setNewFile] = useState("");
  const [fileupload, setFileupload] = useState("");
  const [loading, setLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [modalBtn, setModalBtn] = useState(false);

  const handleImageUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setNewFile(file);
      setFileupload(reader.result);
    };
    reader.readAsDataURL(file);
    setUploadLoading(true);
  };

  const EditProfile = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!gender) {
      setLoading(false);
      toast.error("Please Select Gender");
      return;
    }
    if (!firstName || !lastName || !gender || !bio || !phoneNumber) {
      setLoading(false);
      toast.error("Please Enter All Field");
      return;
    }
    let data = new FormData();
    data.append("first_name", firstName);
    data.append("last_name", lastName);
    data.append("gender", gender);
    data.append("bio", bio);
    data.append("avatar", newFile);
    data.append("phone_no", phoneNumber);
    // data.append("address", address);
    console.log(data);
    UpdateProfile(data, token)
      .then((res) => {
        setLoading(false);
        setUploadLoading(false);
        toast.success(res?.data?.message);
        console.log(res?.data?.response?.data);
        dispatch(EditProfileData(res?.data?.response?.data));
        Navigate("/dashboard/profile");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setUploadLoading(false);
      });
  };

  const changePassword = (e) => {
    e.preventDefault();
    setModalBtn(true);
    let data = new FormData();
    data.append("current_password", currentPassword);
    data.append("password", password);
    data.append("confirm_password", confirmPassword);
    console.log(data);
    PostChangePassword(data, token)
      .then((res) => {
        setModalBtn(false);
        toast.success(res?.data?.message);
        setIsOpenModal(false);
        setCurrentPassword("");
        setPassword("");
        setConfirmPassword("");
      })
      .catch((err) => {
        console.log(err);
        setModalBtn(false);
        setIsOpenModal(false);
      });
  };

  return (
    <>
      <div className="profile-Container">
        <p className="dashboard-title">Edit Profile</p>
        <div className="serviceCard">
          <div className="flexBox-style">
            <div className="profilePicture-container">
              {uploadLoading ? (
                <img src={fileupload} className="image-fluid image-width" />
              ) : (
                <>
                  {userData?.profile_picture == null ? (
                    <img
                      src={`${ramdomImage(
                        `${userData?.first_name}{""}${userData?.last_name}`
                      )}`}
                      className="image-fluid image-width"
                    />
                  ) : (
                    <img
                      src={`${ImageUrl}/${
                        fileupload ? fileupload : userData?.profile_picture
                      }`}
                      className="image-fluid image-width"
                    />
                  )}
                </>
              )}
            </div>
            <div className="edit-container">
              <FontAwesomeIcon icon={faCamera} className="edit-pen-icon" />
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                multiple="false"
              />
            </div>
          </div>
          <p onClick={() => setIsOpenModal(true)} className="change-pass">
            Change Password
          </p>
          <div className="row mt-4">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email"
                  disabled
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <select
                  className="custom-select my-1 mr-sm-2 form-control"
                  id="inlineFormCustomSelectPref"
                  onChange={(e) => setGender(e.target.value)}
                  style={{ borderRadius: "30px" }}
                >
                  <option selected>Gender</option>
                  <option value={3}>Male</option>
                  <option value={4}>Female</option>
                </select>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <textarea
                  type="text"
                  className="form-control"
                  placeholder="Bio"
                  value={bio}
                  onChange={(e) => setBio(e.target.value)}
                  required
                  style={{
                    height: "110px",
                    borderRadius: "16px",
                    padding: "10px 20px",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="btn-container">
            <button
              type="button"
              className="btn btn-primary"
              style={{ width: "unset", padding: "0px 20px" }}
              onClick={(e) => EditProfile(e)}
              disabled={loading}
            >
              {loading ? "loading.." : "Save"}
            </button>
          </div>
        </div>
      </div>
      <ChangePasswordModal
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        currentPassword={currentPassword}
        setCurrentPassword={setCurrentPassword}
        password={password}
        setPassword={setPassword}
        confirmPassword={confirmPassword}
        setConfirmPassword={setConfirmPassword}
        changePassword={changePassword}
        modalBtn={modalBtn}
      />
    </>
  );
}

export default EditProfile;
