import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  SET_PASSWORD_REQUEST,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_ERROR,
  // GET_ME_SUCCESS,
  // SET_PROFILE_IMG,
  // LOGOUT_REQUEST,
  // LOGOUT_SUCCESS,
  // LOGOUT_ERROR,
  LOGOUT,
  USER_TOKEN,
  Term_Condition,
  JOBS,
  EDIT_PROFILE,
  All_PAYMENT_METHOD,
  SERVICES,
  ADDRESS,
  All_JOB_DATA,
  ALL_STATES_DATA,
  NOTIFICATION,
  ALL_CHAT,
  SET_NEW_MESSAGE,
  AUTO_ADDRESS,
  ALL_COUNTRY,
} from "../Types";

const initialState = {
  isLogin: false,
  loading: false,
  // forgotLoading: false,
  // setPasswordLoading: false,
  users: {},
  token: "",
  allJobData: [],
  services: [],
  paymentMethod: [],
  addressData: [],
  statesData: [],
  notifications: {},
  outgoingMessage: {},
  messages: [],
  autoAddress: [],
  country: [],
  termCondition: [],
};
const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return Object.assign({}, state, {
        loading: true,
      });
    }
    case LOGIN_SUCCESS: {
      return Object.assign({}, state, {
        users: action.payload,
        loading: false,
        isLogin: true,
      });
    }
    case LOGIN_ERROR: {
      return Object.assign({}, state, {
        loading: false,
        isLogin: false,
      });
    }
    case USER_TOKEN: {
      return Object.assign({}, state, {
        token: action.payload,
      });
    }
    case SIGNUP_REQUEST: {
      return Object.assign({}, state, {
        loading: true,
      });
    }
    case SIGNUP_SUCCESS: {
      return Object.assign({}, state, {
        users: action.data,
        loading: false,
      });
    }
    case SIGNUP_ERROR: {
      return Object.assign({}, state, {
        loading: false,
        isLogin: false,
      });
    }
    case FORGOT_PASSWORD_REQUEST: {
      return Object.assign({}, state, {
        forgotLoading: true,
        isSuccess: false,
      });
    }
    case FORGOT_PASSWORD_SUCCESS: {
      return Object.assign({}, state, {
        forgotLoading: false,
        isSuccess: true,
      });
    }
    case FORGOT_PASSWORD_ERROR: {
      return Object.assign({}, state, {
        forgotLoading: false,
        isSuccess: false,
      });
    }
    case SET_PASSWORD_REQUEST: {
      return Object.assign({}, state, {
        setPasswordLoading: true,
      });
    }
    case SET_PASSWORD_SUCCESS: {
      return Object.assign({}, state, {
        setPasswordLoading: false,
      });
    }
    case SET_PASSWORD_ERROR: {
      return Object.assign({}, state, {
        setPasswordLoading: false,
      });
    }
    case LOGOUT:
      return {
        users: null,
        isLogin: false,
      };
    case EDIT_PROFILE: {
      return Object.assign({}, state, {
        ...state,
        users: action.payload,
      });
    }
    case All_PAYMENT_METHOD: {
      return Object.assign({}, state, {
        ...state,
        paymentMethod: action.payload,
      });
    }
    case SERVICES: {
      return Object.assign({}, state, {
        ...state,
        services: action.payload,
      });
    }

    case ADDRESS: {
      return Object.assign({}, state, {
        ...state,
        addressData: action.payload,
      });
    }

    case All_JOB_DATA: {
      return Object.assign({}, state, {
        ...state,
        allJobData: action.payload,
      });
    }
    case ALL_STATES_DATA: {
      return Object.assign({}, state, {
        ...state,
        statesData: action.payload,
      });
    }
    case AUTO_ADDRESS: {
      return Object.assign({}, state, {
        ...state,
        autoAddress: action.payload,
      });
    }

    case NOTIFICATION: {
      return Object.assign({}, state, {
        ...state,
        notifications: action.payload,
      });
    }

    case SET_NEW_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };

    case ALL_CHAT: {
      return Object.assign({}, state, {
        ...state,
        messages: action.payload,
      });
    }
    case ALL_COUNTRY: {
      return Object.assign({}, state, {
        ...state,
        country: action.payload,
      });
    }
    case Term_Condition: {
      return Object.assign({}, state, {
        ...state,
        termCondition: action.payload,
      });
    }

    default:
      return state;
  }
};
export default AuthReducer;
