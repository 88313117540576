import React, { useState, useEffect } from "react";
import { BrowserRouter, Link, NavLink, Routes, Outlet } from "react-router-dom";
import $ from "jquery";
import "@fortawesome/fontawesome-svg-core/styles.css";
// import "../../node_modules/@fortawesome/fontawesome-svg-core/styles.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import SideBar from "../../../components/DashboardComp/Sidebar/SideBar";
import { blueLogo, dashLogo } from "../../../constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faBullhorn,
  faCommentAlt,
} from "@fortawesome/free-solid-svg-icons";
import DashboardRoutes from "../../../routes/DashboardRoutes";
import { ramdomImage } from "../../../constant/ConstantFunction";
import "../../../assets/css/DashboardLayout.css";
import { useSelector, useDispatch } from "react-redux";
import { GetNotification } from "../../../network/Network";
import { NotificationApi } from "../../../redux/actions/AuthAction";
import { ImageUrl } from "../../../network/ApiUrl";

function DashboardLayout() {
  const userData = useSelector((state) => state.AuthReducer.users);
  const Token = useSelector((state) => state.AuthReducer.token);
  const Notifications = useSelector((state) => state.AuthReducer.notifications);
  const [type, setType] = useState("");
  const [chatActive, setChatActive] = useState(false);
  const [notificationActive, setNotificationActive] = useState(false);
  const [notification, setNotification] = useState([]);
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const $a = document.querySelector("#sidebar-toggle");
  const toggleNavBar = (e) => {
    e.preventDefault();
    document.querySelector("#wrapper").classList.toggle("toggled");
  };

  const handleActive = (type) => {
    if (type === "chat") {
      setChatActive(true);
      setNotificationActive(false);
    } else if (type === "notification") {
      setNotificationActive(true);
      setChatActive(false);
    }
  };

  //  == Get Notification ==
  useEffect(() => {
    GetNotification(currentPage, Token)
      .then((res) => {
        console.log(res);
        // const total = res?.data?.response?.data?.total;
        // setPageCount(Math.ceil(total / limit));
        setNotification(res?.data?.response?.data?.data);
        // dispatch(NotificationApi(res?.data?.response?.data?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [Notifications]);

  console.log("notification", Notifications);
  return (
    <>
      <div id="wrapper">
        <div id="navbar-wrapper">
          <nav className="navbar navbar-inverse">
            <div
              className="nav-Custom-Container"
              // className="container-fluid"
            >
              {/* test  */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="navbar-header">
                  <a className="navbar-brand nav_logo">
                    <img src={dashLogo} className="image-fluid" />
                  </a>
                </div>

                <div className="navbar-header">
                  <a
                    className="navbar-brand distance-width"
                    id="sidebar-toggle"
                    onClick={(e) => toggleNavBar(e)}
                  >
                    <i className="fa fa-bars"></i>
                  </a>
                </div>
              </div>

              {/* test */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="navbar-header">
                  <Link to="/">Visit website</Link>
                </div>
                <div className="navbar-header">
                  {/* <div className="custom-topBar"> */}
                  {/* <ul> */}
                  <div
                    className={type === "notification" ? "Active" : null}
                    onClick={() => {
                      handleActive(setType("notification"));
                    }}
                  >
                    <Link to="/dashboard/notification">
                      <div className="notification-count">
                        {notification?.length}
                        {/* {Notifications?.length} */}
                      </div>
                      <FontAwesomeIcon
                        icon={faBullhorn}
                        // className="notification-icon"
                        className={
                          type === "notification"
                            ? "Active"
                            : "notification-icon"
                        }
                      />
                    </Link>
                  </div>
                  {/* </ul> */}
                  {/* </div> */}
                </div>

                <div className="navbar-header">
                  <div className="profile-container">
                    <Link to="dashboard/profile">
                      <div className="profile">
                        {userData?.profile_picture == null ? (
                          <img
                            src={`${ramdomImage(
                              `${userData?.first_name}{""}${userData?.last_name}`
                            )}`}
                            className="image-fluid image-width"
                          />
                        ) : (
                          <img
                            src={`${ImageUrl}/${userData?.profile_picture}`}
                            className="image-fluid image-width"
                            alt=""
                          />
                        )}
                      </div>
                      <div className="ml-2">
                        <p className="navbar-profile-name">
                          {userData?.first_name}
                        </p>
                        <p className="navbar-profile-role">Customer</p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <aside id="sidebar-wrapper">
          <SideBar />
        </aside>

        <section id="content-wrapper">
          <div className="row">
            <div className="col-lg-12">
              <DashboardRoutes />
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default DashboardLayout;
