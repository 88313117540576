import React, { useState } from "react";
import { Rating } from "react-simple-star-rating";
import { profile1 } from "../../../constant";
import { PostRating } from "../../../network/Network";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { ImageUrl } from "../../../network/ApiUrl";

function DriverRating() {
  const Navigate = useNavigate();
  const Token = useSelector((state) => state.AuthReducer.token);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const selectedJob = location.state;
  console.log(selectedJob);

  const handleRating = (rate) => {
    if (rate == "20") {
      setRating(1);
    } else if (rate == "40") {
      setRating(2);
    } else if (rate == "60") {
      setRating(3);
    } else if (rate == "80") {
      setRating(4);
    } else {
      setRating(5);
    }
    // setRating(rate);
  };

  const SubmitRating = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!rating || !comment) {
      toast.error("Please Enter All Fields");
      return;
    }
    let data = {
      customer_id: selectedJob?.data?.customer_id,
      driver_id: selectedJob?.data?.driver_id,
      job_id: selectedJob?.data?.id,
      type: 1,
      rating: rating,
      comment: comment,
    };
    PostRating(data, Token)
      .then((res) => {
        setLoading(false);
        console.log(res);
        toast.success("Successfully send");
        setRating("");
        setComment("");
        Navigate("/dashboard/ordertracking");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setRating("");
        setComment("");
      });
  };
  console.log(selectedJob?.data.driver?.profile_picture);
  return (
    <>
      <div className="DriverRating-Container">
        <p className="dashboard-title">Give ratings to Driver</p>
        <div className="serviceCard">
          <div className="flexBox-style">
            <div className="profile">
              <img
                src={`${ImageUrl}/${selectedJob?.data?.driver?.profile_picture}`}
                alt=""
                className="image-fluid image-width"
              />
            </div>
          </div>
          <p className="DriverName">
            {selectedJob?.driver?.first_name}
            {""}
            {selectedJob?.driver?.last_name}
          </p>
          <p className="type">Driver</p>
          <div className="rating-inner-cont">
            <Rating
              onClick={handleRating}
              ratingValue={rating} /* Rating Props */
            />
            {/* <p className="status"> Very Satisfied </p> */}
          </div>
          <div>
            <textarea
              type="text"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Enter Comment"
              style={{
                width: "50%",
                borderRadius: "8px",
                height: "100px",
                padding: "10px 10px",
              }}
            />
          </div>
        </div>
        <div className="btn-container">
          <button
            type="button"
            className="btn btn-primary"
            onClick={(e) => SubmitRating(e)}
            disabled={loading}
          >
            {loading ? "Loading.." : "Submit"}
          </button>
        </div>
      </div>
    </>
  );
}

export default DriverRating;
