import React from "react";
import { Link } from "react-router-dom";
import { whiteLogo } from "../../../constant";

function AuthBackground() {
  return (
    <>
      <div className="daja-image">
        <div className="logoDv">
          <figure>
            <Link to="/">
              <img src={whiteLogo} alt="" />
            </Link>
          </figure>
        </div>
      </div>
    </>
  );
}
export default AuthBackground;
