import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartPlus,
  faHome,
  faShippingFast,
  faCommentDollar,
  faUser,
  faSignOutAlt,
  faQuestionCircle,
  faFlag,
  faFileContract,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons";
import { BrowserRouter, Link, useNavigate } from "react-router-dom";
import { logout } from "../../../redux/actions/AuthAction";
import { useDispatch, useSelector } from "react-redux";
import { createBrowserHistory } from "history";

function SideBar() {
  const dispatch = useDispatch();
  const navigate = useNavigate;
  const history = createBrowserHistory;
  const [type, setType] = useState();
  const [home, setHome] = useState("false");
  const [order, setOrder] = useState("false");
  const [tracking, setTracking] = useState("false");
  const [quote, setQuote] = useState("false");
  const [invoice, setInvoice] = useState("false");
  const [employment, setEmployment] = useState("false");
  const [contract, setContract] = useState("false");
  const [help, setHelp] = useState("false");
  const [paymentmethod, setPaymentmethod] = useState("false");
  const [logoutActive, setLogoutActive] = useState("false");
  const [loading, setLoading] = useState(false);

  const activeStateHandler = (type) => {
    if (type === "home") {
      setHome(true);
      setOrder(false);
      setTracking(false);
      setQuote(false);
      setInvoice(false);
      setPaymentmethod(false);
      setEmployment(false);
      setContract(false);
      setHelp(false);
      setLogoutActive(false);
    } else if (type === "order") {
      setHome(false);
      setOrder(true);
      setTracking(false);
      setQuote(false);
      setInvoice(false);
      setPaymentmethod(false);
      setEmployment(false);
      setContract(false);
      setHelp(false);
      setLogoutActive(false);
    } else if (type === "tracking") {
      setHome(false);
      setOrder(false);
      setTracking(true);
      setQuote(false);
      setInvoice(false);
      setPaymentmethod(false);
      setEmployment(false);
      setContract(false);
      setHelp(false);
      setLogoutActive(false);
    } else if (type === "quote") {
      setHome(false);
      setOrder(false);
      setTracking(false);
      setQuote(true);
      setInvoice(false);
      setPaymentmethod(false);
      setEmployment(false);
      setContract(false);
      setHelp(false);
      setLogoutActive(false);
    } else if (type === "invoice") {
      setHome(false);
      setOrder(false);
      setTracking(false);
      setQuote(false);
      setInvoice(true);
      setPaymentmethod(false);
      setEmployment(false);
      setContract(false);
      setHelp(false);
      setLogoutActive(false);
    } else if (type === "paymentmethod") {
      setHome(false);
      setOrder(false);
      setTracking(false);
      setQuote(false);
      setInvoice(false);
      setPaymentmethod(true);
      setEmployment(false);
      setContract(false);
      setHelp(false);
      setLogoutActive(false);
    } else if (type === "employment") {
      setHome(false);
      setOrder(false);
      setTracking(false);
      setQuote(false);
      setInvoice(false);
      setPaymentmethod(false);
      setEmployment(true);
      setContract(false);
      setHelp(false);
      setLogoutActive(false);
    } else if (type === "contract") {
      setHome(false);
      setOrder(false);
      setTracking(false);
      setQuote(false);
      setInvoice(false);
      setPaymentmethod(false);
      setEmployment(false);
      setContract(true);
      setHelp(false);
      setLogoutActive(false);
    } else if (type === "help") {
      setHome(false);
      setOrder(false);
      setTracking(false);
      setQuote(false);
      setInvoice(false);
      setPaymentmethod(false);
      setEmployment(false);
      setContract(false);
      setHelp(true);
      setLogoutActive(false);
    } else {
      setHome(false);
      setOrder(false);
      setTracking(false);
      setQuote(false);
      setInvoice(false);
      setPaymentmethod(false);
      setEmployment(false);
      setContract(false);
      setHelp(false);
      setLogoutActive(true);
    }
  };

  const LogoutHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    setTimeout(async () => {
      setLoading(false);
      let x = await dispatch(logout());
      window.location.href = "/";
    }, 200);
  };

  return (
    <>
      <ul className="sidebar-nav">
        <li
          className={type === "home" ? "active" : null}
          onClick={() => activeStateHandler(setType("home"))}
        >
          <Link to="/dashboard/services">
            <FontAwesomeIcon
              icon={faHome}
              className="sideBar-icon"
              style={{ fontSize: "16px" }}
            />
            Home
          </Link>
        </li>
        {/* <li
          className={type === "order" ? "active" : null}
          onClick={() => activeStateHandler(setType("order"))}
        >
          <Link to="/dashboard/placeorder">
            <FontAwesomeIcon
              icon={faCartPlus}
              className="sideBar-icon"
              style={{ fontSize: "16px" }}
            />
            Place an Order
          </Link>
        </li> */}
        <li
          className={type === "tracking" ? "active" : null}
          onClick={() => activeStateHandler(setType("tracking"))}
        >
          <Link
            // to="/dashboard/ordertracking"
            to="/dashboard/alljobs"
          >
            <FontAwesomeIcon
              icon={faShippingFast}
              className="sideBar-icon"
              style={{ fontSize: "16px" }}
            />
            Order Tracking
          </Link>
        </li>
        <li
          className={type === "quote" ? "active" : null}
          onClick={() => activeStateHandler(setType("quote"))}
        >
          <Link to="/dashboard/getquote">
            <FontAwesomeIcon
              icon={faCommentDollar}
              className="sideBar-icon"
              style={{ fontSize: "16px" }}
            />
            Get a Quote
          </Link>
        </li>
        <li
          className={type === "invoice" ? "active" : null}
          onClick={() => activeStateHandler(setType("invoice"))}
        >
          <Link to="/dashboard/invoice">
            <FontAwesomeIcon
              icon={faUser}
              className="sideBar-icon"
              style={{ fontSize: "16px" }}
            />
            Invoices
          </Link>
        </li>
        <li
          className={type === "paymentmethod" ? "active" : null}
          onClick={() => activeStateHandler(setType("paymentmethod"))}
        >
          <Link to="/dashboard/paymentmethod">
            {/* {"/dashboard/paymentmethod", { state: "home" }} */}
            <FontAwesomeIcon
              icon={faCreditCard}
              className="sideBar-icon"
              style={{ fontSize: "16px" }}
            />
            Paymentmethod
          </Link>
        </li>

        {/* <li
          className={type === "employment" ? "active" : null}
          onClick={() => activeStateHandler(setType("employment"))}
        >
          <Link to="/dashboard/employmentopportunity">
            <FontAwesomeIcon
              icon={faFlag}
              className="sideBar-icon"
              style={{ fontSize: "16px" }}
            />
            Employment Opportunity
          </Link>
        </li> */}
        {/* <li
          className={type === "contract" ? "active" : null}
          onClick={() => activeStateHandler(setType("contract"))}
        >
          <Link to="/dashboard/ContractorOpportunity">
            <FontAwesomeIcon
              icon={faFileContract}
              className="sideBar-icon"
              style={{ fontSize: "16px" }}
            />
            Contract Opportunity
          </Link>
        </li> */}
        <li
          className={type === "help" ? "active" : null}
          onClick={() => activeStateHandler(setType("help"))}
        >
          <Link to="/dashboard/reportissues">
            <FontAwesomeIcon
              icon={faQuestionCircle}
              className="sideBar-icon"
              style={{ fontSize: "16px" }}
            />
            Help
          </Link>
        </li>
        <li
          className={type === "logout" ? "active" : null}
          onClick={() => activeStateHandler(setType("logout"))}
        >
          <a href="" onClick={(e) => LogoutHandler(e)}>
            <FontAwesomeIcon
              icon={faSignOutAlt}
              className="sideBar-icon"
              style={{ fontSize: "16px" }}
            />
            {loading ? "Loading.." : "Logout"}
          </a>
        </li>
      </ul>
    </>
  );
}

export default SideBar;
