import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

function ChangePasswordModal(props) {
  const {
    isOpenModal,
    setIsOpenModal,
    currentPassword,
    setCurrentPassword,

    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    changePassword,
    modalBtn,
  } = props;

  return (
    <>
      <Modal
        isOpen={isOpenModal}
        toggle={() => setIsOpenModal(false)}
        className="custom-modal modal-width Address-Modal"
      >
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="changePass-cancel"
          onClick={() => setIsOpenModal(false)}
        />
        <h2 className="modal-title">Change Password</h2>
        <hr />
        <div className="row">
          <div className="col-md-12 mb-3 changePass-modal ">
            <input
              type="text"
              placeholder="Current Password"
              value={currentPassword}
              onChange={(e) => {
                setCurrentPassword(e.target.value);
              }}
            />
          </div>
          <div className="col-md-12 mb-3 changePass-modal ">
            <input
              type="text"
              placeholder="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
          <div className="col-md-12 mb-3 changePass-modal ">
            <input
              type="text"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="mb-3 mt-3">
          <button
            type="button"
            className="btn btn-primary"
            style={{ width: "unset" }}
            onClick={(e) => changePassword(e)}
            disabled={modalBtn}
          >
            {modalBtn ? "Loading..." : "Save"}
          </button>
        </div>
      </Modal>
    </>
  );
}

export default ChangePasswordModal;
