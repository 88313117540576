import React, { useState } from "react";
import { createBrowserHistory } from "history";
import AuthBackground from "../../components/LandingpageComp/Auth/AuthBackground";
import { whiteLogo, blueLogo } from "../../constant";
import { useSelector, useDispatch } from "react-redux";
import { forgotPasswordOtpVerify } from "../../redux/actions/AuthAction";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function OtpVerification() {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const Location = useLocation();
  const [otpCode, setOtpCode] = useState("");
  const [loading, setLoading] = useState(false);
  const history = createBrowserHistory();

  console.log(Location);
  const OtpVerificationHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!otpCode) {
      toast.error("please enter OTP Code");
      setLoading(false);
      return;
    }
    let data = {
      code: otpCode,
    };
    let param = {
      email: Location?.state,
      otpCode: otpCode,
    };
    console.log(data);
    let send = await dispatch(forgotPasswordOtpVerify(data, param, Navigate));
    // window.location.href = "/createpassword";
    // if (statusCode) {
    //   Navigate("/createpassword", { state: param });
    // }

    setTimeout(() => {
      setLoading(false);
    }, 600);

    // setTimeout(() => {
    //   setLoading(false);
    //   // window.location.href = "/dashboard/services";
    //   history.push("/createpassword");
    // }, 600);
  };

  return (
    <>
      <section className="entercode">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 p-0">
              <AuthBackground />
            </div>
            <div className="col-lg-6 p-0">
              <div className="dajaFrom">
                <div className="form-Flex">
                  <div className="logoDv">
                    <figure>
                      <img src={blueLogo} alt="" />
                    </figure>
                  </div>
                  <h2 className="title blueish">Enter Code</h2>
                  <div className="textDv">
                    <h4 className="subtext authText">Please Enter OTP to</h4>
                    <h4 className="bld authText-sub">
                      <strong>Create a new Password</strong>
                    </h4>
                  </div>
                  <form>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Code "
                        value={otpCode}
                        onChange={(e) => {
                          setOtpCode(e.target.value);
                        }}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <button
                        className="form-control btn"
                        type="submit"
                        onClick={(e) => {
                          OtpVerificationHandler(e);
                        }}
                        disabled={loading}
                      >
                        {loading ? "Loading.." : "Submit"}
                      </button>
                    </div>
                    <div className="privacyDv">
                      <h5>
                        By Creating Account you are agree to our{" "}
                        <Link to="/termcondition" className="blueish">
                          Terms & Conditions
                        </Link>
                      </h5>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default OtpVerification;
