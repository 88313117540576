import React from "react";
import { Link } from "react-router-dom";
import { about } from "../../../constant";
import { useSelector } from "react-redux";

function Aboutus() {
  const termConditionData = useSelector(
    (state) => state.AuthReducer.termCondition
  );
  return (
    <>
      <section className="about-section mt-5" id="about">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6 col-12 mt-4">
              <img src={about} alt="" className="img-fluid aboout-img" />
            </div>
            {termConditionData?.map((item, index) => {
              return (
                <>
                  {item?.slug === "about-us" ? (
                    <>
                      <div className="col-md-6 col-sm-6 col-12 about-content">
                        <h3 className="text-dark font-weight-bold">
                          {item?.title}
                        </h3>
                        <p className="text-dark">{item?.content}</p>
                        <Link
                          to="/termcondition"
                          className="btn btn-primary login-btn ml-4"
                          href=""
                        >
                          Learn More
                        </Link>
                      </div>
                    </>
                  ) : null}
                </>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}

export default Aboutus;
