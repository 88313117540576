import React from "react";

function OrderDeliveryForm(props) {
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    city,
    setCity,
    street,
    setStreet,
    phoneNumber,
    setPhoneNumber,
    zipCode,
    setZipCode,
    deliveryAddress,
    setDeliveryAddress,
    originAddress,
    setOriginAddress,
    cityOrigin,
    setCityOrigin,
    zipOrigin,
    setZipOrigin,
    countryOrigin,
    setCountryOrigin,
    stateOrigin,
    setStateOrigin,
  } = props;

  return (
    <>
      <div className="formCard">
        <div>
          <p className="subTitle">Write Your Delivery Details</p>
          <hr />
        </div>
        <form className="inputForm-padding">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                  required
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  required
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="City"
                  value={city}
                  onChange={(e) => {
                    setCity(e.target.value);
                  }}
                  required
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Street"
                  value={street}
                  onChange={(e) => {
                    setStreet(e.target.value);
                  }}
                  required
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="form-group">
                <input
                  type="number"
                  pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                  className="form-control"
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                  }}
                  required
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="form-group">
                {/* <select
                  className="custom-select my-1 mr-sm-2 form-control"
                  id="inlineFormCustomSelectPref"
                  onChange={(e) => {
                    setZipCode(e.target.value);
                  }}
                >
                  <option selected>Zip Code</option>
                  <option value={1}>000</option>
                  <option value={2}>001</option>
                </select> */}
                <input
                  type="text"
                  className="form-control"
                  placeholder="Zip Code"
                  value={zipCode}
                  onChange={(e) => {
                    setZipCode(e.target.value);
                  }}
                  required
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Delivery Address"
                  value={deliveryAddress}
                  onChange={(e) => {
                    setDeliveryAddress(e.target.value);
                  }}
                  required
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="address_origin"
                  value={originAddress}
                  onChange={(e) => {
                    setOriginAddress(e.target.value);
                  }}
                  required
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="city origin"
                  value={cityOrigin}
                  onChange={(e) => {
                    setCityOrigin(e.target.value);
                  }}
                  required
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Country origin"
                  value={countryOrigin}
                  onChange={(e) => {
                    setCountryOrigin(e.target.value);
                  }}
                  required
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="zip origin"
                  value={zipOrigin}
                  onChange={(e) => {
                    setZipOrigin(e.target.value);
                  }}
                  required
                />
              </div>
            </div>

            <div className="col-md-6 col-sm-12">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="state origin"
                  value={stateOrigin}
                  onChange={(e) => {
                    setStateOrigin(e.target.value);
                  }}
                  required
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default OrderDeliveryForm;
