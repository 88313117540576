export const ApiUrl =
  "https://developer-ourbase-camp.com/daja/public/api" ||
  "http://localhost:4000";
export const ImageUrl =
  "https://developer-ourbase-camp.com/daja/public" || "http://localhost:4000";

// export const ApiUrl =
//   "http://192.168.3.46/daja_transport/public/api" || "http://localhost:4000";
// export const ImageUrl =
//   "http://192.168.3.46/daja_transport/public" || "http://localhost:4000";

// galem72812@videour.com
