import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { Outlet, useNavigate, useLocation } from "react-router";
import Navbar from "../../../components/LandingpageComp/Navbar/Navbar";
import moment from "moment";

function SelectedContractorOpportunity() {
  const Navigate = useNavigate();
  const Location = useLocation();
  const selectedOptData = Location.state;
  console.log(Location);
  return (
    <>
      <Navbar page={"employment"} />
      <div className="Employment-Container mt-5 mb-5">
        <p className="dashboard-title mt-5">Contractor Opportunity</p>
        <div className="serviceCard">
          <p className="EmploymentTitle">{selectedOptData?.title}</p>
          <hr />
          <div className="job-header">
            {selectedOptData?.is_featured ? (
              <div className="feature-tag">Featured</div>
            ) : null}

            <div className="flexBox-style">
              <FontAwesomeIcon icon={faStar} className="starIcon" />
              <div>
                <p className="address">Arkahm, 2552 Street</p>
                <p className="address">
                  {" "}
                  {moment(selectedOptData?.updated_at).format("MMMM Do YYYY")}
                </p>
              </div>
            </div>
          </div>
          <div>
            <p className="jobDesc-title">Job Description</p>
            <p className="job-para">{selectedOptData?.description}</p>
            {/* <p className="job-para mt-5">
              Duis aute irure dolor in reprehenderit in voluptate velit esse
              cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
              cupidatat non proident, sunt in culpa qui officia deserunt mollit
              anim id est laborum. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco laboris nisi ut aliquip ex ea commodo
              consequat.
            </p> */}
          </div>
        </div>
        <div className="btn-container">
          <button
            type="button"
            className="btn btn-primary"
            style={{ width: "unset" }}
            onClick={() => {
              Navigate("/contractorform", { state: selectedOptData });
            }}
          >
            Apply Now
          </button>
        </div>
      </div>
    </>
  );
}

export default SelectedContractorOpportunity;
