import moment from "moment";
import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { ramdomImage } from "../../../constant/ConstantFunction";

function NotificationCard(props) {
  const Navigate = useNavigate();
  const { Notifications, item, index } = props;
  console.log(Notifications);
  const NotificationViewHandler = (e) => {
    e.preventDefault();
    if (item.type == "job") {
      Navigate("/dashboard/alljobs");
    }
  };
  return (
    <>
      <div className="row" key={item?.id}>
        {/* <div className="col-md-4 col-4">
          <div className="notification-profile">
            <img
              src={`${ramdomImage("test")}`}
              className="image-fluid image-width"
              alt=""
            />
          </div>
        </div> */}
        <div className="col-md-8 col-12">
          <p className="notification-title">
            {item?.description === "Job is in progress"
              ? "starting the job"
              : item?.description}
          </p>
          <p className="date">
            {moment(item?.updated_at).format("MMMM Do YYYY")}
            {/* {moment(item?.updated_at).format("MMMM Do YYYY, h:mm a")} */}
          </p>
        </div>
        <div className="col-md-4 col-12">
          <div>
            <button
              type="button"
              className="btn btn-primary"
              style={{ width: "unset" }}
              onClick={(e) => NotificationViewHandler(e)}
            >
              View
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default NotificationCard;
