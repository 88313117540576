import React from "react";

function FeaturesCard(props) {
  const { item, index } = props;
  return (
    <>
      <div className="col-md-3 col-sm-3 text-center" key={index}>
        <div className="step1">
          <img src={item?.img} alt="" className="img-fluid mb-3" />
          <h6 className="text-light">{item?.title}</h6>
        </div>
      </div>
    </>
  );
}

export default FeaturesCard;
