import React, { useState, useEffect } from "react";
import EmploymentSearchCard from "../../../components/DashboardComp/Employment/EmploymentSearchCard";
import Navbar from "../../../components/LandingpageComp/Navbar/Navbar";
import {
  GetAllCountry,
  GetAllDepart,
  ShowAllEmploymentJobs,
  ShowAllJobs,
} from "../../../network/Network";
import { useSelector, useDispatch } from "react-redux";
import { SpinnerCircular } from "spinners-react";
import { toast } from "react-toastify";

function EmploymentOpportunity() {
  const Token = useSelector((state) => state.AuthReducer.token);
  const [department, setDepartment] = useState("");
  const [country, setCountry] = useState("");
  const [searchTitle, setSearchTitle] = useState("");

  const [departData, setDepartData] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [jobData, setJobData] = useState([]);

  const [spinLoad, setSpinLoad] = useState(false);

  //  == All Depart ==
  useEffect(() => {
    GetAllDepart(Token)
      .then((res) => {
        console.log(res);
        setDepartData(res?.data?.response?.data);
        // dispatch(AllServices(res?.data?.response?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //  == All Country ==
  useEffect(() => {
    GetAllCountry(Token)
      .then((res) => {
        console.log(res);
        setCountryData(res?.data?.response?.data);
        // dispatch(AllServices(res?.data?.response?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // == Search Job ==
  const Search = (e) => {
    setSpinLoad(true);
    e.preventDefault();
    let data = {
      title: searchTitle,
      department_id: department,
      country_id: country,
    };
    ShowAllEmploymentJobs(data, Token)
      .then((res) => {
        console.log(res);
        setJobData(res?.data?.response?.data?.data);
        setSpinLoad(false);
        toast.info(res?.data?.message);
        // dispatch(AllServices(res?.data?.response?.data));
        setSearchTitle("");
      })
      .catch((err) => {
        console.log(err);
        setSpinLoad(false);
        setSearchTitle("");
      });
  };

  return (
    <>
      <Navbar page={"employment"} />
      <div className="EmploymentOpt-Container mb-5 mt-5">
        <p className="dashboard-title mt-5">Employment Opportunity</p>
        <div className="serviceCard">
          <p className="EmploymentTitle">Search For Jobs</p>
          <hr />
          <form>
            <div className="row mt-4">
              <div className="col-md-3">
                <div className="form-group">
                  <select
                    className="jobCard-Dropdown custom-select my-1 mr-sm-2 form-control"
                    id="inlineFormCustomSelectPref"
                    onChange={(e) => setDepartment(e.target.value)}
                  >
                    <option selected>Department</option>
                    {departData?.map((item, index) => {
                      return (
                        <option value={item?.id} key={item?.id}>
                          {item?.title}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <select
                    className="jobCard-Dropdown custom-select my-1 mr-sm-2 form-control"
                    id="inlineFormCustomSelectPref"
                    onChange={(e) => setCountry(e.target.value)}
                  >
                    <option selected>Country</option>
                    {countryData?.map((item, index) => {
                      return (
                        <option value={item?.id} key={item?.id}>
                          {item?.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="track-package-section">
                  <input
                    type="text"
                    className="search-input"
                    placeholder="Search Employments"
                    value={searchTitle}
                    onChange={(e) => setSearchTitle(e.target.value)}
                  />
                  <button className="search-btn" onClick={(e) => Search(e)}>
                    Search
                  </button>
                </div>
              </div>
            </div>
          </form>
          <div className="flexBox-space">
            <p className="searchCount-result">
              Browse results in: Los Angeles {">"} jobs {">"} Courier
            </p>
            <p className="searchCount-result">{jobData?.length} Results</p>
          </div>
          <div className="searchjob-scroll">
            <div className="row mt-4">
              {spinLoad ? (
                <>
                  <div className="loader-container">
                    <SpinnerCircular size="80px" color="#FF9517" />
                  </div>
                </>
              ) : (
                jobData?.map((item, index) => {
                  return <EmploymentSearchCard item={item} index={index} />;
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmploymentOpportunity;
