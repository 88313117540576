import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import TermCondition from "../components/LandingpageComp/TermCondition";
import CreatePassword from "../pages/AuthViews/CreatePassword";
import ForgetPassword from "../pages/AuthViews/ForgetPassword";
import Login from "../pages/AuthViews/Login";
import OtpVerification from "../pages/AuthViews/OtpVerification";
import SignUp from "../pages/AuthViews/SignUp";
import ContractorForm from "../pages/Dashboard/Contractor/ContractorForm";
import ContractorOpportunity from "../pages/Dashboard/Contractor/ContractorOpportunity";
import SelectedContractorOpportunity from "../pages/Dashboard/Contractor/SelectedContractorOpportunity";
import EmploymentForm from "../pages/Dashboard/Employment/EmploymentForm";
import EmploymentOpportunity from "../pages/Dashboard/Employment/EmploymentOpportunity";
import SelectedEmploymentOpportunity from "../pages/Dashboard/Employment/SelectedEmploymentOpportunity";
import Layout from "../pages/LandingPage/Layout";
// import { Redirect } from "react-router";

function AuthRoutes() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Layout />} />
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<SignUp />} />
          <Route path="forgetpassword" element={<ForgetPassword />} />
          <Route path="otpverification" element={<OtpVerification />} />
          <Route path="createpassword" element={<CreatePassword />} />

          <Route
            path="employmentopportunity"
            element={<EmploymentOpportunity />}
          />
          <Route
            path="selectedemploymentopportunity"
            element={<SelectedEmploymentOpportunity />}
          />
          <Route path="employmentform" element={<EmploymentForm />} />
          <Route
            path="contractoropportunity"
            element={<ContractorOpportunity />}
          />
          <Route path="contractorform" element={<ContractorForm />} />
          <Route
            path="selectedcontractoropportunity"
            element={<SelectedContractorOpportunity />}
          />
          <Route path="termcondition" element={<TermCondition />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default AuthRoutes;

// {
//   /* <Route path="*" element={<NoMatch />} /> */
// }
// {
//   /* <Redirect to="/login" /> */
// }
