import React, { useState } from "react";
import { test, blueLogo } from "../../../constant";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ImageUrl } from "../../../network/ApiUrl";
import AddressModal from "../../../components/DashboardComp/Modal/AddressModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { PostAddress } from "../../../network/Network";
import { toast } from "react-toastify";
import { AddressData } from "../../../redux/actions/AuthAction";
import { ramdomImage } from "../../../constant/ConstantFunction";

function Profile() {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.AuthReducer.users);
  const Token = useSelector((state) => state.AuthReducer.token);
  //== Modal states ==
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [label, setLabel] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("");
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [sendCord, setSendCord] = useState({
    lat: null,
    lng: null,
  });

  const SubmitAddress = (e) => {
    setLoadingBtn(true);
    e.preventDefault();
    let data = {
      label: label,
      address: address,
      city: city,
      state: state,
      zip: zip,
      country: country,
      lat: sendCord?.lat,
      lng: sendCord?.lng,
    };
    console.log(data);
    PostAddress(data, Token)
      .then((res) => {
        console.log(res);
        setLoadingBtn(false);
        dispatch(AddressData(res?.data?.response?.data));
        toast.success(res?.data?.message);
        setIsOpenModal(false);
        setLabel("");
        setAddress("");
        setCity("");
        setState("");
        setZip("");
        setCountry("");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.errors?.message);
        setLoadingBtn(false);
        setIsOpenModal(false);
        setLabel("");
        setAddress("");
        setCity("");
        setState("");
        setZip("");
        setCountry("");
      });
  };

  return (
    <>
      <div className="profile-Container">
        <p className="dashboard-title">Profile</p>
        <div className="serviceCard">
          <div className="flexBox-style">
            <div className="profilePicture-container">
              {/* <img src={test} alt="" className="image-fluid image-width" /> */}
              {userData?.profile_picture == null ? (
                <img
                  src={`${ramdomImage(
                    `${userData?.first_name}{""}${userData?.last_name}`
                  )}`}
                  className="image-fluid image-width"
                />
              ) : (
                <img
                  src={`${ImageUrl}/${userData?.profile_picture}`}
                  className="image-fluid image-width"
                />
              )}
            </div>
          </div>
          <p
            className="edit-profile"
            onClick={() => Navigate("/dashboard/editprofile")}
          >
            Edit Profile
          </p>
          <span
            onClick={() => {
              setIsOpenModal(true);
            }}
            className="edit-profile"
            style={{ fontSize: "14px" }}
          >
            <FontAwesomeIcon icon={faPlusSquare} className="mr-2" />
            Add Address
          </span>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <p className="user-title">First Name</p>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <p className="user-details">{userData?.first_name}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-sm-12">
                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <p className="user-title">Last Name</p>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <p className="user-details">{userData?.last_name}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-sm-12">
                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <p className="user-title">Phone Number</p>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <p className="user-details">{userData?.phone_no}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-sm-12">
                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <p className="user-title">Address</p>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <p className="user-details">{userData?.address}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-sm-12">
                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <p className="user-title">Gender</p>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <p className="user-details">{userData?.gender}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-sm-12">
                  <div className="row">
                    <div className="col-md-6 col-sm-6">
                      <p className="user-title">Bio</p>
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <p className="user-details">{userData?.bio}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <img src={blueLogo} className="image-fluid" alt="" />
            </div>
          </div>
        </div>
      </div>
      <AddressModal
        setIsOpenModal={setIsOpenModal}
        isOpenModal={isOpenModal}
        label={label}
        setLabel={setLabel}
        address={address}
        setAddress={setAddress}
        city={city}
        setCity={setCity}
        state={state}
        setState={setState}
        zip={zip}
        setZip={setZip}
        country={country}
        setCountry={setCountry}
        SubmitAddress={SubmitAddress}
        loadingBtn={loadingBtn}
        setLoadingBtn={setLoadingBtn}
        setSendCord={setSendCord}
      />
    </>
  );
}

export default Profile;
