import {
  SETTING,
  SHOWSTATE,
  AUTH,
  LOGIN,
  SIGNUP,
  FROGOT_PASSWORD,
  CHECK_CODE,
  PAYMENT,
  STORE_CARD,
  SHOW_CARD,
  REMOVE_CARD,
  SERVICES,
  QUOTESTORE,
  USER,
  UPDATE,
  UPDATEDEFAULTCARD,
  UPDATE_PROFILE,
  SHOWSERVICE,
  CUSTOMER,
  JOB,
  STORE,
  PAGE,
  TRACKING,
  CUSTOMERTRACKING,
  SHOWDEPART,
  SHOW_COUNTRY,
  SHOWEMPLOYMENTS,
  STORERESUMES,
  SHOW_CONTRACTOR,
  ADDRESS,
  SHOW,
  STORECONTRACTOROPPORTUNITY,
  REPORT,
  CHAT,
  CREATE,
  GET,
  MESSAGE,
  SEND,
  NOTIFICATION,
  INVOICE,
  RESEST,
  RATING,
  CHANGE_PASSWORD,
  SHOW_PAGE,
  PAYMENT_METHODS,
  NOTIFICATIONS,
} from "../network/Endpoint";

import {
  doGet,
  doPost,
  doPatch,
  doDelete,
  doPostProfilePictureUpload,
} from "./Config";

export const postSignIn = (data) => {
  return doPost(`${AUTH}${LOGIN}`, { ...data });
};

export const postSignUp = (data) => {
  return doPost(`${AUTH}${SIGNUP}`, data);
};

export const forgotPasswordApi = (data) => {
  return doPost(`${AUTH}${RESEST}`, data);
};

export const forgotPasswordEmailApi = (data) => {
  return doPost(`${AUTH}${FROGOT_PASSWORD}`, data);
};

export const forgotPasswordOtp = (data) => {
  return doPost(`${AUTH}${CHECK_CODE}`, data);
};

export const GetAllState = () => {
  return doGet(`${SETTING}${SHOWSTATE}/231`);
};

export const AddPaymentMethods = (data, token) => {
  return doPost(`${CUSTOMER}${PAYMENT}${STORE_CARD}`, data, token);
};

export const GetAllPaymentMethods = (token) => {
  return doGet(`${CUSTOMER}${PAYMENT}${SHOW_CARD}`, token);
};

export const DeletePaymentMethodsApi = (data, token) => {
  return doPost(`${CUSTOMER}${PAYMENT}${REMOVE_CARD}`, data, token);
};

export const PostQuote = (data, token) => {
  return doPost(`${SETTING}${QUOTESTORE}`, data, token);
};

export const UpdateProfile = (data, token) => {
  return doPostProfilePictureUpload(`${USER}${UPDATE}`, data, token);
};

export const GetAllService = (currentPage, token) => {
  return doGet(`${SETTING}${SHOWSERVICE}${PAGE}=${currentPage}`, token);
};

export const PostPaymentDefaultCard = (data, token) => {
  return doPost(`${CUSTOMER}${PAYMENT}${UPDATEDEFAULTCARD}`, data, token);
};

export const POSTJOBS = (data, token) => {
  return doPost(`${CUSTOMER}${JOB}${STORE}`, data, token);
};

export const GetAllDepart = (token) => {
  return doGet(`${SETTING}${SHOWDEPART}`, token);
};

export const GetAllCountry = (token) => {
  return doGet(`${SETTING}${SHOW_COUNTRY}`, token);
};

export const ShowAllJobs = (currentPage, token) => {
  return doGet(`${CUSTOMER}${JOB}${SHOW}${PAGE}=${currentPage}`, token);
};

export const PostResume = (data, token) => {
  return doPost(`${SETTING}${STORERESUMES}`, data, token);
};

export const GetAllContractorJobs = (currentPage) => {
  return doGet(`${SETTING}${SHOW_CONTRACTOR}${PAGE}=${currentPage}`);
};

export const PostAddress = (data, token) => {
  return doPost(`${CUSTOMER}${ADDRESS}${STORE}`, data, token);
};

export const GetAllAddress = (token) => {
  return doGet(`${CUSTOMER}${ADDRESS}${SHOW}`, token);
};

export const GetAllJobs = (token) => {
  return doGet(`${CUSTOMER}${JOB}${SHOW}`, token);
};

export const PostContractorOpportunity = (data, token) => {
  return doPost(`${SETTING}${STORECONTRACTOROPPORTUNITY}`, data, token);
};

export const ReportIssue = (data, token) => {
  return doPost(`${CUSTOMER}${REPORT}${STORE}`, data, token);
};

export const CreateChat = (data, token) => {
  return doPost(`${CHAT}${CREATE}`, data, token);
};

export const GetMessage = (data, token) => {
  return doPost(`${CHAT}${GET}${MESSAGE}`, data, token);
};

export const SendMessage = (data, token) => {
  return doPost(`${CHAT}${SEND}${MESSAGE}`, data, token);
};

export const GetNotification = (currentPage, token) => {
  return doGet(
    `${CUSTOMER}${NOTIFICATION}${SHOW}${PAGE}=${currentPage}`,
    token
  );
};

export const PostRating = (data, token) => {
  return doPost(`${CUSTOMER}${RATING}${STORE}`, data, token);
};

export const GetInvoice = (currentPage, token) => {
  return doGet(`${CUSTOMER}${JOB}${INVOICE}${PAGE}=${currentPage}`, token);
};

export const PostChangePassword = (data, token) => {
  return doPost(`${USER}${CHANGE_PASSWORD}`, data, token);
};

export const ShowAllEmploymentJobs = (data, token) => {
  return doPost(`${SETTING}${SHOWEMPLOYMENTS}`, data, token);
};

export const GetTracking = (id, token) => {
  return doGet(`${CUSTOMERTRACKING}/${id}`, token);
};

export const GetCountry = () => {
  return doGet(`${SETTING}${SHOW_COUNTRY}`);
};

export const TermCondition = () => {
  return doGet(`${SETTING}${SHOW_PAGE}`);
};

export const UpdateNotification = (currentPage, token) => {
  return doGet(
    `${CUSTOMER}${NOTIFICATION}${UPDATE}${PAGE}=${currentPage}`,
    token
  );
};

// ---END --

export const setNewPasswordApi = (data) => {
  // return doPost(`${AUTH}${}`, data);
};
export const logoutApi = (data) => {
  // return doPost(`${AUTH}${}`, data);
};
