import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { PostAddress } from "../../../network/Network";
import { AddressData } from "../../../redux/actions/AuthAction";
import AddressModal from "../Modal/AddressModal";

function ServiceTypeForm(props) {
  const dispatch = useDispatch();
  const {
    serviceType,
    setServiceType,
    items,
    setItems,
    code,
    setCode,
    pickUpAddress,
    setPickUpAddress,
    dropOffAddress,
    setDropOffAddress,
    setOriginContactPerson,
    originContactPerson,
    setDestinationContactPerson,
    destinationContactPerson,
    allAddress,
  } = props;
  const AddressDataRedux = useSelector(
    (state) => state.AuthReducer.addressData
  );
  const Token = useSelector((state) => state.AuthReducer.token);
  //== Modal states ==
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [label, setLabel] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("");
  const [sendCord, setSendCord] = useState({
    lat: null,
    lng: null,
  });
  const [loadingBtn, setLoadingBtn] = useState(false);

  console.log(sendCord);

  const SubmitAddress = (e) => {
    setLoadingBtn(true);
    if (!label || !address || !city || !state || !zip || !country) {
      toast.error("please Enter All Fields");
      setLoadingBtn(false);
      return;
    }
    e.preventDefault();
    let data = {
      label: label,
      address: address,
      city: city,
      state: state,
      zip: zip,
      country: country,
      lat: sendCord?.lat,
      lng: sendCord?.lng,
    };
    console.log(data);
    PostAddress(data, Token)
      .then((res) => {
        console.log(res);
        setLoadingBtn(false);
        dispatch(AddressData(res?.data?.response?.data));
        // dispatch(AddressData(res?.data?.response?.data));
        toast.success(res?.data?.message);
        if (res?.data?.statusCode == 200) {
          setLoadingBtn(false);
          setIsOpenModal(false);
          setLabel("");
          setAddress("");
          setCity("");
          setState("");
          setZip("");
          setCountry("");
          return;
        }
      })
      .catch((err) => {
        console.log(err);
        console.log(err?.response?.data?.errors);
        toast.error(err?.response?.data?.errors);
        // toast.error(err?.errors);
        setLoadingBtn(false);
        setIsOpenModal(false);
      });
  };

  return (
    <>
      <div className="formCard">
        <div>
          <p className="subTitle">Select Service Type</p>
          <hr />
        </div>
        <form className="inputForm-padding">
          <div className="row">
            <div className="mb-5">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  setIsOpenModal(true);
                }}
              >
                Add Address
              </button>
            </div>

            <div className="col-md-6">
              <div className="form-group">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Items"
                  min="1"
                  value={items}
                  onChange={(e) => setItems(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Code"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
              </div>
            </div>
          </div>
        </form>

        <form className="inputForm-padding">
          <p className="form-InnerTitle">Pickup Address</p>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <select
                  className="custom-select my-1 mr-sm-2 form-control"
                  id="inlineFormCustomSelectPref"
                  onChange={(e) => {
                    setPickUpAddress(e.target.value);
                  }}
                >
                  <option selected>Select Your Address</option>
                  {AddressDataRedux?.map((item, index) => {
                    return (
                      <option value={item?.id} key={item?.id}>
                        {item?.address}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Contact Person Name"
                  value={originContactPerson}
                  onChange={(e) => setOriginContactPerson(e.target.value)}
                  required
                />
              </div>
            </div>
          </div>
        </form>

        <form className="inputForm-padding">
          <p className="form-InnerTitle">Drop Off Address</p>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                {/* <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Your Address"
                  value={dropOffAddress}
                  onChange={(e) => setDropOffAddress(e.target.value)}
                  required
                /> */}
                <select
                  className="custom-select my-1 mr-sm-2 form-control"
                  id="inlineFormCustomSelectPref"
                  onChange={(e) => {
                    setDropOffAddress(e.target.value);
                  }}
                >
                  <option selected>Select Your Address</option>
                  {AddressDataRedux?.map((item, index) => {
                    return (
                      <option value={item?.id} key={item?.id}>
                        {item?.address}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Contact Person Name"
                  value={destinationContactPerson}
                  onChange={(e) => setDestinationContactPerson(e.target.value)}
                  required
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      <AddressModal
        setIsOpenModal={setIsOpenModal}
        isOpenModal={isOpenModal}
        label={label}
        setLabel={setLabel}
        address={address}
        setAddress={setAddress}
        city={city}
        setCity={setCity}
        state={state}
        setState={setState}
        zip={zip}
        setZip={setZip}
        country={country}
        setCountry={setCountry}
        SubmitAddress={SubmitAddress}
        loadingBtn={loadingBtn}
        setLoadingBtn={setLoadingBtn}
        setSendCord={setSendCord}
      />
    </>
  );
}

export default ServiceTypeForm;
