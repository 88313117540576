import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

export default function Inputplace(props) {
  const {
    setPropData,
    setCoordinates,
    setZip,
    setCity,
    setState,
    setCountry,
    setAddress,
    setSendCord,
  } = props;

  const [addressData, setAddressData] = React.useState("");
  // const [coordinates, setCoordinates] = React.useState({
  //   lat: null,
  //   lng: null,
  // });

  const handleSelect = async (value) => {
    console.log(value);
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddressData(value);
    setPropData(results);
    setCoordinates(latLng);
    setSendCord(latLng);
    let data = [];
    console.log(results[0].address_components[0].types[0]);
    for (let i = 0; i <= results[0].address_components.length; i++) {
      if (results[0]?.address_components[i]?.types[0] === "street_number") {
        data.push({ street: results[0]?.address_components[i].long_name });
      } else if (results[0]?.address_components[i]?.types[0] === "country") {
        setCountry(results[0]?.address_components[i].long_name);
      } else if (
        results[0]?.address_components[i]?.types[0] === "postal_code"
      ) {
        setZip(results[0]?.address_components[i].long_name);
      } else if (results[0]?.address_components[i]?.types[0] === "route") {
        setAddress(results[0]?.address_components[i].long_name);
      } else if (
        results[0]?.address_components[i]?.types[0] ===
        "administrative_area_level_1"
      ) {
        setState(results[0]?.address_components[i].long_name);
      } else if (
        results[0]?.address_components[i]?.types[0] ===
        "administrative_area_level_2"
      ) {
        setCity(results[0]?.address_components[i].long_name);
      }
    }
    console.log(data);
  };

  return (
    <div>
      <PlacesAutocomplete
        value={addressData}
        onChange={setAddressData}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            {/* <p>Latitude: {coordinates.lat}</p>
            <p>Longitude: {coordinates.lng}</p> */}

            <input
              {...getInputProps({ placeholder: "Type address" })}
              className="autoInput"
            />

            <div>
              {loading ? <div>...loading</div> : null}

              {suggestions.map((suggestion) => {
                const style = {
                  backgroundColor: suggestion.active ? "#41b6e6" : "#fff",
                };

                return (
                  <div
                    {...getSuggestionItemProps(suggestion, { style })}
                    className="autocomplete-dropdown-container"
                  >
                    <p>{suggestion.description}</p>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
}

// import React from "react";
// import {
//   geocodeByAddress,
//   geocodeByPlaceId,
//   getLatLng,
// } from "react-places-autocomplete";
// import PlacesAutocomplete from "react-places-autocomplete";
// import { useSelector, useDispatch } from "react-redux";
// import { AutoAddress } from "../../../redux/actions/AuthAction";

// export class Inputplace extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { address: "" };
//   }

//   handleChange = (address) => {
//     this.setState({ address });
//   };

//   handleSelect = (address) => {
//     geocodeByAddress(address)
//       .then((results) => {
//         getLatLng(results[0]);
//         this.props.dispatch(AutoAddress(results));
//         console.log(results);
//       })
//       .then((latLng) => console.log("Success", latLng))
//       .catch((error) => console.error("Error", error));
//   };

//   render() {
//     return (
//       <PlacesAutocomplete
//         value={this.state.address}
//         onChange={this.handleChange}
//         onSelect={this.handleSelect}
//       >
//         {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
//           <div>
//             <input
//               {...getInputProps({
//                 placeholder: "Search Places ...",
//                 className: "location-search-input",
//               })}
//             />
//             <div className="autocomplete-dropdown-container">
//               {console.log({ suggestions })}
//               {loading && <div>Loading...</div>}
//               {suggestions.map((suggestion) => {
//                 const className = suggestion.active
//                   ? "suggestion-item--active"
//                   : "suggestion-item";
//                 const style = suggestion.active
//                   ? { backgroundColor: "#fafafa", cursor: "pointer" }
//                   : { backgroundColor: "#ffffff", cursor: "pointer" };
//                 return (
//                   <div
//                     {...getSuggestionItemProps(suggestion, {
//                       className,
//                       style,
//                     })}
//                   >
//                     <span>{suggestion.description}</span>
//                   </div>
//                 );
//               })}
//             </div>
//           </div>
//         )}
//       </PlacesAutocomplete>
//     );
//   }
// }
