import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Link, Navigate, Outlet, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ShowAllJobs } from "../../../network/Network";
import { toast } from "react-toastify";
import { AllJobData } from "../../../redux/actions/AuthAction";
import { createBrowserHistory } from "history";
import { SpinnerCircular } from "spinners-react";
import ReactPaginate from "react-paginate";

function AllJobs() {
  const history = createBrowserHistory();
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const Token = useSelector((state) => state.AuthReducer.token);
  const [trackingNum, setTrackingNum] = useState("");
  const [allJobData, setAllJobData] = useState([]);
  const [spinLoad, setSpinLoad] = useState(true);

  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  let limit = 10;

  const handlePageClick = async (data) => {
    console.log(data.selected);
    setCurrentPage(data?.selected + 1);
  };

  useEffect(() => {
    setSpinLoad(true);
    ShowAllJobs(currentPage, Token)
      .then((res) => {
        console.log(res);
        setAllJobData(res?.data?.response?.data?.data);
        dispatch(AllJobData(res?.data?.response?.data?.data));
        setSpinLoad(false);
        const total = res?.data?.response?.data?.total;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((err) => {
        console.log(err);
        setSpinLoad(false);
      });
  }, [currentPage]);

  const ViewJob = (item, e) => {
    e.preventDefault();
    Navigate("/dashboard/ordertracking", { state: item });
  };

  return (
    <>
      <div className="orderTracking-Container">
        <p className="dashboard-title">Jobs</p>
        <div className="serviceCard">
          <div className="jobs-card">
            {spinLoad ? (
              <>
                <div className="loader-container">
                  <SpinnerCircular size="80px" color="#FF9517" />
                </div>
              </>
            ) : (
              <table className="table">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Tracking code</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Driver</th>
                    <th scope="col">Status</th>
                    <th scope="col">Track</th>
                  </tr>
                </thead>
                <tbody>
                  {allJobData?.map((item, index) => {
                    return (
                      <tr key={item?.id}>
                        <th scope="row">{index + 1}</th>
                        <td>{item?.tracking_code}</td>
                        <td>{item?.amount}</td>
                        <td>
                          {item?.driver ? (
                            <>
                              {item?.driver?.first_name}{" "}
                              {item?.driver?.last_name}
                            </>
                          ) : (
                            "--"
                          )}
                        </td>
                        <td>
                          {item?.status === "In Progress"
                            ? "Job Start"
                            : item?.status}
                        </td>
                        <td>
                          {item?.status == "Pending" ? (
                            <div>Pending</div>
                          ) : (
                            <div>
                              <button
                                className="form-control btn"
                                onClick={(e) => ViewJob(item, e)}
                                // disabled={loading}
                              >
                                View
                              </button>
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
            <div className="pagination-container">
              <ReactPaginate
                // renderOnZeroPageCount={null}
                previousLabel="< previous"
                nextLabel="next >"
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
}

export default AllJobs;
