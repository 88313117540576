import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import Inputplace from "../PlaceAutoComplete/PlaceAutoComplete";
import { useDispatch } from "react-redux";

function AddressModal(props) {
  console.log(props);
  const {
    isOpenModal,
    setIsOpenModal,
    label,
    setLabel,
    address,
    setAddress,
    city,
    setCity,
    state,
    setState,
    zip,
    setZip,
    country,
    setCountry,
    SubmitAddress,
    loadingBtn,
    setLoadingBtn,
    setSendCord,
  } = props;

  const [propData, setPropData] = useState();
  const [coordinates, setCoordinates] = React.useState({
    lat: null,
    lng: null,
  });
  console.log(propData);

  return (
    <>
      <Modal
        isOpen={isOpenModal}
        toggle={() => setIsOpenModal(false)}
        className="custom-modal modal-width Address-Modal"
      >
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="close"
          onClick={() => setIsOpenModal(false)}
        />
        <h2 className="modal-title">Add Address</h2>
        <hr />
        {/* <p>Latitude: {coordinates.lat}</p>
        <p>Longitude: {coordinates.lng}</p> */}
        <p>Auto Complete Place</p>
        <div className="mt-2 mb-3">
          <Inputplace
            setPropData={setPropData}
            setCoordinates={setCoordinates}
            setZip={setZip}
            setCity={setCity}
            setState={setState}
            setCountry={setCountry}
            setAddress={setAddress}
            setSendCord={setSendCord}
          />
        </div>
        <div className="row">
          <div className="col-md-12 mb-3">
            <input
              type="text"
              placeholder="Label"
              value={label}
              onChange={(e) => {
                setLabel(e.target.value);
              }}
              className="inputAddress-modal"
            />
          </div>
          <div className="col-md-12 mb-3">
            <input
              type="text"
              placeholder="Address"
              value={address}
              onChange={(e) => {
                setAddress(e.target.value);
              }}
              className="inputAddress-modal"
            />
          </div>
          <div className="col-md-12 mb-3">
            <input
              type="text"
              placeholder="city"
              value={city}
              onChange={(e) => {
                setCity(e.target.value);
              }}
              className="inputAddress-modal"
            />
          </div>
          <div className="col-md-12 mb-3">
            <input
              type="text"
              placeholder="state"
              value={state}
              onChange={(e) => {
                setState(e.target.value);
              }}
              className="inputAddress-modal"
            />
          </div>
          <div className="col-md-12 mb-3">
            <input
              type="text"
              placeholder="zip"
              value={zip}
              onChange={(e) => {
                setZip(e.target.value);
              }}
              className="inputAddress-modal"
            />
          </div>
          <div className="col-md-12 mb-3">
            <input
              type="text"
              placeholder="country"
              value={country}
              onChange={(e) => {
                setCountry(e.target.value);
              }}
              className="inputAddress-modal"
            />
          </div>
        </div>

        <div className="mb-3 mt-3">
          <button
            type="button"
            className="btn btn-primary"
            // style={{ width: "unset" }}
            onClick={(e) => {
              SubmitAddress(e);
            }}
            disabled={loadingBtn}
          >
            {loadingBtn ? "Loading..." : "Post"}
          </button>
        </div>
      </Modal>
    </>
  );
}

export default AddressModal;
