import React, { useState, useEffect } from "react";
import { whiteLogo, blueLogo } from "../../constant";
import AuthBackground from "../../components/LandingpageComp/Auth/AuthBackground";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AllStates, signUp } from "../../redux/actions/AuthAction";
import { GetAllState } from "../../network/Network";
import { toast } from "react-toastify";

function SignUp() {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const token = useSelector((state) => state.AuthReducer.token);
  const countryData = useSelector((state) => state.AuthReducer.country);
  const stateData = useSelector((state) => state.AuthReducer.statesData);

  console.log(stateData);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [address, setAddress] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState("");

  const SignUpHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      !firstName ||
      !lastName ||
      !email ||
      !phone ||
      !country ||
      !state ||
      !address ||
      !zipCode ||
      !password ||
      !confirmPassword
    ) {
      toast.error("Please Enter All Field");
      setLoading(false);
      return;
    }
    if (password !== confirmPassword) {
      toast.error("Password must be same");
      setLoading(false);
      return;
    }
    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      toast.error("Invalid Email");
      setLoading(false);
      return;
    } else {
      let data = new FormData();
      data.append("first_name", firstName);
      data.append("last_name", lastName);
      data.append("email", email);
      data.append("phone_no", phone);
      data.append("country", country);
      data.append("state", state);
      data.append("address", address);
      data.append("zip", zipCode);
      data.append("role", "customer");
      data.append("password", password);
      data.append("confirm_password", confirmPassword);
      console.log(data);
      setLoading(false);

      let send = await dispatch(signUp(data));
      Navigate("/login");
      // window.location.href = "/login";
      // setTimeout(() => {
      //   if (send) {
      //     setLoading(false);
      //     // Navigate("/login");
      //   }
      // }, 600);
    }
  };

  useEffect(() => {
    GetAllState()
      .then((res) => {
        console.log("STATE", res);
        dispatch(AllStates(res?.data?.response?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <section className="singnup">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 p-0">
              <AuthBackground />
            </div>
            <div className="col-lg-6 p-0">
              <div className="dajaFrom">
                <div className="form-Flex">
                  <div className="logoDv">
                    <figure>
                      <img src={blueLogo} alt="" />
                    </figure>
                  </div>
                  <h2 className="title pb-3 blueish">Sign Up</h2>
                  <div className="textDv pb-2">
                    <h4 className="subtext authText">
                      Welcome back to Daja Transport
                    </h4>
                    <h4 className="bld authText-sub">
                      <strong>We you Help to move your deliveries</strong>
                    </h4>
                  </div>
                  <form>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Phone Number"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <select
                            className="custom-select my-1 mr-sm-2 form-control"
                            id="inlineFormCustomSelectPref"
                            onChange={(e) => {
                              setCountry(e.target.value);
                            }}
                          >
                            <option selected>Country</option>
                            {countryData?.map((item, index) => {
                              return (
                                <option value={item?.id} key={item?.id}>
                                  {item?.name}
                                </option>
                              );
                            })}
                          </select>
                          {/* <select
                            className="custom-select my-1 mr-sm-2 form-control"
                            id="inlineFormCustomSelectPref"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                          >
                            <option selected>Country</option>
                            <option value={1}>USA</option>
                            <option value={2}>UK</option>
                          </select> */}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <select
                            className="custom-select my-1 mr-sm-2 form-control"
                            id="inlineFormCustomSelectPref"
                            onChange={(e) => {
                              setState(e.target.value);
                            }}
                          >
                            <option selected>States</option>
                            {stateData?.map((item, index) => {
                              return (
                                <option value={item?.id} key={item?.id}>
                                  {item?.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Address"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Zip Code"
                            value={zipCode}
                            onChange={(e) => setZipCode(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <button
                        className="form-control btn"
                        type="submit"
                        onClick={(e) => SignUpHandler(e)}
                        disabled={loading}
                      >
                        {loading ? "Loading.." : "Create Account"}
                      </button>
                    </div>
                  </form>
                  <div className="memberLink pb-1">
                    <p>
                      Already a Member?
                      <Link to="/login" className="blueish">
                        Sign In
                      </Link>
                    </p>
                  </div>
                  <div className="privacyDv">
                    <h5>
                      By Creating Account you are agree to our{" "}
                      <Link to="/termcondition" className="blueish">
                        Terms & Conditions
                      </Link>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SignUp;
