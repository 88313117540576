import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthBackground from "../../components/LandingpageComp/Auth/AuthBackground";
import { blueLogo } from "../../constant";
import { useSelector, useDispatch } from "react-redux";
import { login } from "../../redux/actions/AuthAction";
import { createBrowserHistory } from "history";
import { toast } from "react-toastify";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate;
  const token = useSelector((state) => state.AuthReducer.token);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleRememeberMe = (event) => {
    // const value = input.type === "checkbox" ? input.checked : input.value;
    setChecked(!checked);
  };

  const SignInHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!email || !password) {
      toast.error("Please Enter All Fields");
      setLoading(false);
      return;
    } else {
      localStorage.setItem("rememberMe", checked);
      localStorage.setItem("email", checked ? email : "");
      localStorage.setItem("password", checked ? password : "");

      let data = {
        email: email,
        password: password,
        role: "customer",
      };
      console.log(data);
      setTimeout(async () => {
        setLoading(false);
        let x = await dispatch(login(data));
        console.log("response login", x);
        if (x) {
          console.log(x);
          console.log({ token });
          window.location.href = "/dashboard/services";
        }
      }, 600);
    }
  };

  console.log(checked);

  useEffect(() => {
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = rememberMe ? localStorage.getItem("email") : "";
    const password = rememberMe ? localStorage.getItem("password") : "";
    setEmail(email);
    setPassword(password);
    setChecked(rememberMe);
  }, []);

  return (
    <>
      <section className="singnin">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 p-0">
              <AuthBackground />
            </div>
            <div className="col-lg-6 p-0">
              <div className="dajaFrom">
                <div className="form-Flex">
                  <div className="logoDv">
                    <figure>
                      <Link to="/">
                        <img src={blueLogo} alt="" />
                      </Link>
                    </figure>
                  </div>
                  <h2 className="title pb-3 blueish">Sign In</h2>
                  <div className="textDv pb-2">
                    <h4 className="subtext authText">
                      Welcome back to Daja Transport
                    </h4>
                    <h4 className="bld authText-sub">
                      <strong>We you Help to move your deliveries</strong>
                    </h4>
                  </div>
                  <div className="memberLink pb-3  authText">
                    <p>
                      Not a Member?
                      <Link to="/signup" className="blueish">
                        Sign Up
                      </Link>
                    </p>
                  </div>
                  <form>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <button
                        className="form-control btn"
                        onClick={(e) => SignInHandler(e)}
                        disabled={loading}
                      >
                        {loading ? "Loading..." : "Sign In Your Account"}
                      </button>
                    </div>
                    <div className="optionDv">
                      <div className="rememberDv">
                        <div className="form-check flexBox-style">
                          <input
                            name="rememberMe"
                            value={checked}
                            onChange={(e) => handleRememeberMe(e)}
                            type="checkbox"
                          />

                          <label for="blankCheckbox" className="ml-2">
                            Remember Me
                          </label>
                        </div>
                      </div>
                      <div className="forgotDv">
                        <Link to="/forgetpassword" className="blueish">
                          Forgot Password?
                        </Link>
                      </div>
                    </div>
                    <div className="privacyDv">
                      <h5>
                        By Creating Account you are agree to our{" "}
                        <Link to="/termcondition" className="blueish">
                          Terms & Conditions
                        </Link>
                      </h5>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
