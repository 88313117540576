import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import ContractorCard from "../../../components/DashboardComp/Contractor/ContractorCard";
import { Navigate, useNavigate, Outlet } from "react-router";
import Navbar from "../../../components/LandingpageComp/Navbar/Navbar";
import { useSelector, useDispatch } from "react-redux";
import { GetAllContractorJobs } from "../../../network/Network";
import { SpinnerCircular } from "spinners-react";
import ReactPaginate from "react-paginate";

function ContractorOpportunity() {
  const Navigate = useNavigate();
  const [optSate, setOptState] = useState("");
  const [requestId, setRequestId] = useState("");
  const [city, setCity] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [contractorData, setContractorData] = useState([]);
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const [spinLoad, setSpinLoad] = useState(false);

  const cardData = [
    {
      id: 1,
      title: "Assistant to General Manager",
      desc: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.",
    },
    {
      id: 2,
      title: "Assistant to General Manager",
      desc: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.",
    },
    {
      id: 3,
      title: "Assistant to General Manager",
      desc: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.",
    },
    {
      id: 4,
      title: "Assistant to General Manager",
      desc: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.",
    },
  ];

  const handlePageClick = async (data) => {
    console.log(data.selected);
    setCurrentPage(data?.selected + 1);
  };

  useEffect(() => {
    setSpinLoad(true);
    GetAllContractorJobs(currentPage)
      .then((res) => {
        console.log(res);
        setContractorData(res?.data?.response?.data?.data);
        const total = res?.data?.response?.data?.total;
        const limit = res?.data?.response?.data?.per_page;
        setPageCount(Math.ceil(total / limit));
        setSpinLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinLoad(false);
      });
  }, [currentPage]);

  const cardDetailView = (e) => {
    e.preventDefault();
    // Navigate("/contractoropportunityform");
    // window.location.href = "/selectedcontractoropportunity";
  };

  return (
    <>
      <Navbar page={"employment"} />
      <div className="contractor-Container mb-5 mt-5">
        <p className="dashboard-title mt-5">Contractor Opptunities</p>
        <div className="serviceCard">
          <div className="row">
            <div className="col-md-1">
              <FontAwesomeIcon icon={faQuestionCircle} />
            </div>
            <div className="col-md-11">
              <p className="contractor-desc">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.{" "}
              </p>
              <p className="contractor-desc">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua
                consequat.
              </p>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          {spinLoad ? (
            <>
              <div className="loader-container">
                <SpinnerCircular size="80px" color="#FF9517" />
              </div>
            </>
          ) : (
            contractorData?.map((item, index) => {
              return (
                <ContractorCard
                  item={item}
                  index={index}
                  cardDetailView={cardDetailView}
                />
              );
            })
          )}
        </div>
        <div className="pagination-container mt-5">
          <ReactPaginate
            // renderOnZeroPageCount={null}
            previousLabel="< previous"
            nextLabel="next >"
            breakLabel={"..."}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div>
      <Outlet />
    </>
  );
}

export default ContractorOpportunity;
