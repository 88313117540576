import React, { useState, useEffect } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { footerLogo } from "../../../constant";
import { ramdomImage } from "../../../constant/ConstantFunction";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { ImageUrl } from "../../../network/ApiUrl";

function Navbar(props) {
  const { page } = props;
  const Navigate = useNavigate();
  const isLogin = useSelector((state) => state.AuthReducer.isLogin);
  const userData = useSelector((state) => state.AuthReducer.users);
  const [colorChange, setColorchange] = useState(false);

  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  return (
    <>
      <header
        // id="header"
        className={`fixed-top d-flex align-items-center ${
          colorChange ? "colorChange" : page != "LandingPage" ? "bg-dark" : null
        }`}
      >
        <nav
          className={`navbar navbar-expand-lg navbar-light mobile-view ${
            page === "LandingPage" ? " bg-transparent" : "bg-dark navText-white"
          }`}
        >
          <div className="logo">
            <Link to="/">
              <img src={footerLogo} alt="" className="img-fluid nav_logo" />
            </Link>
          </div>
          <input type="checkbox" id="click" />
          <label for="click" className="menu-btn">
            {/* <i class="fas fa-bars"></i> */}
            <FontAwesomeIcon icon={faBars} />
          </label>
          {/* <div className="collapse navbar-collapse" id="navbarSupportedContent"> */}
          <ul className="navbar-nav mr-auto">
            <li className="nav-item ">
              <Link to="/" className="nav-link">
                Home <span className="sr-only">(current)</span>
              </Link>
            </li>
            <li className="nav-item ">
              <a href="#about" className="nav-link">
                About
              </a>
            </li>
            <li className="nav-item ">
              <a href="#services" className="nav-link">
                Service
              </a>
            </li>
            {/* <li className="nav-item ">
              <a href="#pricing" className="nav-link">
                Pricing
              </a>
            </li> */}
            {/* <li className="nav-item ">
              <a className="nav-link" href="#">
                Contact
              </a>
            </li> */}
            {/* <li className="nav-item">
              <a className="nav-link" href="#">
                Track
              </a>
            </li> */}
            <li className="nav-item">
              <Link to="/employmentopportunity" className="nav-link">
                Employment
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/contractoropportunity" className="nav-link">
                Contract
              </Link>
            </li>
            {isLogin ? null : (
              <li className="nav-item">
                <Link to="/signup" className="nav-link">
                  Join Us
                </Link>
              </li>
            )}

            {isLogin ? (
              <li className="nav-item">
                <div
                  onClick={() => Navigate("/dashboard/services")}
                  className="flexBox-style user-responsive"
                >
                  <div className="landing-profile">
                    {userData?.profile_picture == null ? (
                      <img
                        src={`${ramdomImage(
                          `${userData?.first_name} ${userData?.last_name}`
                        )}`}
                        className="image-fluid image-width"
                      />
                    ) : (
                      <img
                        src={`${ImageUrl}/${userData?.profile_picture}`}
                        className="image-fluid image-width"
                        alt=""
                      />
                    )}
                  </div>
                  <p className="landing-user">
                    {userData?.first_name} {userData?.last_name}
                  </p>
                </div>
              </li>
            ) : (
              <li className="nav-item">
                <Link
                  to="/login"
                  className="btn btn-primary login-btn ml-4"
                  style={{ width: "unset" }}
                >
                  Login
                </Link>
              </li>
            )}
          </ul>
          {/* </div> */}
        </nav>
        {/* <nav
          className={`navbar navbar-expand-lg navbar-light mobile-view ${
            page === "LandingPage" ? " bg-transparent" : "bg-dark navText-white"
          }`}
        >
          <div className="logo">
            <Link to="/">
              <img src={footerLogo} alt="" className="img-fluid nav_logo" />
            </Link>
          </div>

          <div>
            <div className="Ham_innerContainer">
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mr-auto">
                <li className="nav-item ">
                  <Link to="/" className="nav-link">
                    Home <span className="sr-only">(current)</span>
                  </Link>
                </li>
                <li className="nav-item ">
                  <a href="#about" className="nav-link">
                    About
                  </a>
                </li>
                <li className="nav-item ">
                  <a href="#services" className="nav-link">
                    Service
                  </a>
                </li>
                <li className="nav-item ">
                  <a href="#pricing" className="nav-link">
                    Pricing
                  </a>
                </li>
                <li className="nav-item ">
                  <a className="nav-link" href="#">
                    Contact
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">
                    Track
                  </a>
                </li>
                <li className="nav-item">
                  <Link to="/employmentopportunity" className="nav-link">
                    Employment
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/contractoropportunity" className="nav-link">
                    Contract
                  </Link>
                </li>

                <li className="nav-item">
                  <a className="nav-link" href="#">
                    Join Us
                  </a>
                </li>
                {isLogin ? (
                  <li className="nav-item">
                    <div
                      onClick={() => Navigate("/dashboard/services")}
                      className="flexBox-style"
                    >
                      <div className="landing-profile">
                        <img
                          src={`${ramdomImage(
                            `${userData?.first_name} ${userData?.last_name}`
                          )}`}
                          className="image-fluid image-width"
                          alt=""
                        />
                      </div>
                      <p className="landing-user">
                        {userData?.first_name} {userData?.last_name}
                      </p>
                    </div>
                  </li>
                ) : (
                  <li className="nav-item">
                    <Link
                      to="login"
                      className="btn btn-primary login-btn ml-4"
                      style={{ width: "unset" }}
                    >
                      Login
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </nav> */}

        {/* <div className="container d-flex justify-content-between align-items-center">
          <div className="logo">
            <a href="index.php">
              <img src={footerLogo} alt="" className="img-fluid" />
            </a>
          </div>
          <nav id="navbar" className="navbar">
           
            <ul>
              <li>
                <a className="active" href="javascript:void(0);">
                  Home
                </a>
              </li>
              <li>
                <a href="">About</a>
              </li>
              <li>
                <a href="">Service</a>
              </li>
              <li>
                <a href="">Pricing</a>
              </li>
              <li>
                <a href="">Contact</a>
              </li>
              <li>
                <a href="">Track</a>
              </li>
              <li>
                <a href="">Join Us</a>
              </li>
              <li>
                <Link
                  to="login"
                  className="btn btn-primary login-btn ml-4"
                  style={{ width: "unset" }}
                >
                  Login
                </Link>
              </li>
            </ul>
            <i className="fa fa-bars mobile-nav-toggle d-lg-none"></i>
          </nav>
        </div> */}
      </header>
      {/* <a
        href="#"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="fa fa-angle-up"></i>
      </a> */}
      <Outlet />
    </>
  );
}

export default Navbar;
