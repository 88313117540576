import React from "react";
import { useSelector } from "react-redux";

function InvoiceTable(props) {
  const { item, index } = props;
  const Services = useSelector((state) => state.AuthReducer.services);
  const serviceData = Services?.find((x) => x.id === item?.job?.service_id);
  console.log(serviceData);
  console.log({ item });
  return (
    <>
      <tr key={item?.id}>
        <th scope="row" className="tableData">
          {item?.job?.tracking_code}
        </th>
        <td className="tableData">{serviceData?.name}</td>
        <td className="tableData">{item?.job?.item}</td>
        <td className="tableData">{item?.job?.amount}</td>
        <td className="tableData">{item?.job?.origin_address?.address}</td>
        <td className="tableData">
          {item?.job?.destination_address?.address}
          <br />
          {item?.job?.contact_name_destination}
          <br />
          {item?.job?.contactNumber}
          <br />
        </td>
        <td className="tableData">{item?.job?.status}</td>
      </tr>
    </>
  );
}

export default InvoiceTable;

{
  /* <table class="table table-striped">
<thead>
  <tr>
    <th scope="col" className="tableHead">
      Order No
    </th>
    <th scope="col" className="tableHead">
      Service Type
    </th>
    <th scope="col" className="tableHead">
      Item Details
    </th>
    <th scope="col" className="tableHead">
      Amount
    </th>
    <th scope="col" className="tableHead">
      Pick up Details
    </th>
    <th scope="col" className="tableHead">
      Drop Off Location
    </th>
  </tr>
</thead>
<tbody>
  <tr>
    <th scope="row" className="tableData">
      xxxxx
    </th>
    <td className="tableData">Serivce1</td>
    <td className="tableData">
      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
    </td>
    <td className="tableData">$XXX</td>
    <td className="tableData">Address, Contact Name, Contact No</td>
    <td className="tableData">Address, Contact Name, Contact No</td>
  </tr>
  <tr>
    <th scope="row">xxxxx</th>
    <td>Serivce1</td>
    <td>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
    </td>
    <td>$XXX</td>
    <td>Address, Contact Name, Contact No</td>
    <td>Address, Contact Name, Contact No</td>
  </tr>
  <tr>
    <th scope="row">xxxxx</th>
    <td>Serivce1</td>
    <td>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
    </td>
    <td>$XXX</td>
    <td>Address, Contact Name, Contact No</td>
    <td>Address, Contact Name, Contact No</td>
  </tr>
</tbody>
</table> */
}
