import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { ramdomImage } from "../../../constant/ConstantFunction";
import { useSelector, useDispatch } from "react-redux";
import { CreateChat, GetMessage, SendMessage } from "../../../network/Network";
import { useLocation } from "react-router-dom";
import { ImageUrl } from "../../../network/ApiUrl";
import { pusher } from "../../../index";
import {
  AllChatData,
  setNewMessageData,
} from "../../../redux/actions/AuthAction";
import { toast } from "react-toastify";

function Chat() {
  const dispatch = useDispatch();
  const Token = useSelector((state) => state.AuthReducer.token);
  const MessageRedux = useSelector((state) => state.AuthReducer.messages);
  const UserData = useSelector((state) => state.AuthReducer.users);
  const location = useLocation();
  const selectedJob = location.state;
  const [message, setMessage] = useState("");

  const [makeChatData, setMakeChatData] = useState([]);
  const [allMessages, setAllMessages] = useState([]);

  useEffect(async () => {
    let data = await {
      customer_id: UserData?.id,
      driver_id: selectedJob?.data?.driver_id,
    };
    console.log(data);
    CreateChat(data, Token)
      .then((res) => {
        console.log(res);
        setMakeChatData(res?.data?.response?.data);
        let response_id = res?.data?.response?.data?.id;
        if (response_id) {
          let data = {
            chat_id: response_id,
          };
          console.log(data);
          GetMessage(data, Token)
            .then((res) => {
              console.log(res?.data?.response?.data);
              dispatch(AllChatData(res?.data?.response?.data.reverse()));
              setAllMessages(res?.data?.response?.data);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Get Message api
  const SendMessageHandler = (e) => {
    e.preventDefault();
    let data = {
      chat_id: makeChatData?.id,
      receiver_id: selectedJob?.data?.driver_id,
      message: message,
    };
    SendMessage(data, Token)
      .then((res) => {
        console.log(res);
        // allMessages.push(...allMessages, res?.data?.response?.data);
        // setAllMessages(allMessages);
        // dispatch(AllChatData(allMessages));
        dispatch(setNewMessageData(res?.data?.response?.data));
        console.log("response message", res?.data?.response?.data);
        setMessage("");
      })
      .catch((err) => {
        console.log(err);
        setMessage("");
      });
  };

  // Messages Pusher
  const channel = pusher.subscribe(`message.${UserData?.id}`);
  useEffect(() => {
    channel.bind("App\\Events\\MessageEvent", (data) => {
      console.log("cldata", data);
      // alert("New Chat");
      toast.info("You Have a New Message!!");
      // console.log("mk", makeChatData?.id);
      // console.log(data?.msg);
      if (data?.msg && data?.msg?.chat_id == makeChatData?.id) {
        console.log("event called", data?.msg);
        setAllMessages((prevMsg) => [data?.msg, ...prevMsg]);
        dispatch(setNewMessageData(allMessages));
        console.log("pm", allMessages);
      }
    });
  }, []);

  return (
    <>
      <div className="chat-Container">
        <div className="serviceCard">
          <div className="chat-header">
            <div className="profile">
              {makeChatData?.driver?.profile_picture == null ? (
                <img
                  src={`${ramdomImage(
                    `${makeChatData?.driver?.first_name}{""}${makeChatData?.driver?.last_name}`
                  )}`}
                  className="image-fluid image-width"
                />
              ) : (
                <img
                  src={`${ImageUrl}/${makeChatData?.driver?.profile_picture}`}
                  className="image-fluid image-width"
                  alt=""
                />
              )}
            </div>
            <div
              className={`${
                makeChatData?.driver?.is_online == "1" ? "online" : "offline"
              }`}
            ></div>
            <div className="ml-3">
              <p className="user-name">{makeChatData?.driver?.first_name}</p>
              <p className="status">{`${
                makeChatData?.driver?.is_online == "1"
                  ? "Online Now"
                  : "Offline"
              }`}</p>
            </div>
          </div>
          <hr />
          <div className="All-message-container">
            {MessageRedux?.map((item, index) => {
              return (
                <div
                  className={`${
                    item?.sender?.id == UserData?.id ? "outgoing" : "incoming"
                  }`}
                >
                  <div
                    className={`${
                      item?.sender?.id == UserData?.id
                        ? "chat-picture-outgoing"
                        : "chat-picture-incoming"
                    }`}
                  >
                    {/* <img
                      src={
                        item?.sender_type_id === UserData?.id
                          ? `${ImageUrl}/${UserData?.profile_picture}`
                          : `${ImageUrl}/${makeChatData?.driver?.profile_picture}`
                      }
                      className="image-fluid image-width"
                    /> */}
                    <img
                      src={
                        item?.sender_type_id === UserData?.id
                          ? UserData?.profile_picture == null
                            ? `${ramdomImage(
                                `${UserData?.first_name}{""}${UserData?.last_name}`
                              )}`
                            : `${ImageUrl}/${UserData?.profile_picture}`
                          : makeChatData?.driver?.profile_picture == null
                          ? `${ramdomImage(
                              `${makeChatData?.driver?.first_name}{""}${makeChatData?.driver?.last_name}`
                            )}`
                          : `${ImageUrl}/${makeChatData?.driver?.profile_picture}`
                      }
                      className="image-fluid image-width"
                    />
                  </div>
                  <div
                    className={`${
                      item?.sender?.id == UserData?.id
                        ? "chat-message-outgoing"
                        : "chat-message-incoming"
                    }`}
                  >
                    <p className="chat-text" key={item?.id}>
                      {item?.message}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="input-Card">
          <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Write Your Message"
          />
          <div className="send-container">
            <button
              type="button"
              className="btn send-btn"
              style={{ width: "unset" }}
              onClick={(e) => SendMessageHandler(e)}
            >
              <FontAwesomeIcon icon={faPaperPlane} className="service-icon" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Chat;
