import React from "react";
import { Link } from "react-router-dom";
import {
  footerLogo,
  facebook,
  instaIcon,
  twitterIcon,
  youtubeIcon,
  linkdnIcon,
} from "../../../constant";

function Footer() {
  return (
    <>
      <footer className="footer-16371">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9 text-center">
              <div className="footer-site-logo mb-4">
                <a href="">
                  <img src={footerLogo} alt="" className="img-fluid" />
                </a>
              </div>
              <ul className="list-unstyled nav-links mb-5">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <a href="#about">About</a>
                </li>
                <li>
                  <a href="#services">Service</a>
                </li>
                {/* <li>
                  <a href="">Pricing</a>
                </li>
                <li>
                  <a href="">Contact</a>
                </li>
                <li>
                  <a href="">Track</a>
                </li> */}
              </ul>
              <div className="social mb-4">
                <ul className="list-unstyled">
                  <li className="fb">
                    <a href="#" className="fb-icon">
                      <span className="icon-facebook">
                        <img src={facebook} alt="" className="img-fluid" />
                      </span>
                    </a>
                  </li>
                  <li className="in">
                    <a href="#">
                      <span className="icon-instagram">
                        <img src={instaIcon} alt="" className="img-fluid" />
                      </span>
                    </a>
                  </li>
                  <li className="tw">
                    <a className="twit-bg" href="#">
                      <span className="icon-twitter">
                        <img src={twitterIcon} alt="" className="img-fluid" />
                      </span>
                    </a>
                  </li>
                  <li className="pin">
                    <a href="#">
                      <span className="icon-pinterest">
                        <img src={youtubeIcon} alt="" className="img-fluid" />
                      </span>
                    </a>
                  </li>
                  <li className="dr">
                    <a href="#">
                      <span className="icon-dribbble">
                        <img src={linkdnIcon} alt="" className="img-fluid" />
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="copyright">
                <p className="mb-0">
                  <span className="footer-address font-weight-normal">
                    Address : 11 Florence Dr, Monroe Township, NJ{" "}
                  </span>
                </p>
                <p className="mb-0">
                  <span className="footer-address font-weight-normal">
                    Call : 973-997-3224{" "}
                  </span>
                </p>
              </div>
              <hr className="footer-line" />
              <div className="sub-menu row">
                <div className="col-md-6">
                  <p className="text-left">
                    Daja Transport 2021 All Rights Reserved
                  </p>
                </div>
                <div className="col-md-6">
                  <ul className="text-right footer-sub-menu">
                    <li>
                      <Link to="/termcondition"> Term & Conditions</Link>
                    </li>
                    {/* <li>
                      <a href=""> Privacy Policy</a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
