import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TermCondition from "../components/LandingpageComp/TermCondition";
import ContractorForm from "../pages/Dashboard/Contractor/ContractorForm";
import ContractorOpportunity from "../pages/Dashboard/Contractor/ContractorOpportunity";
import SelectedContractorOpportunity from "../pages/Dashboard/Contractor/SelectedContractorOpportunity";
import DashboardLayout from "../pages/Dashboard/DashboardLayout/DashboardLayout";
import EmploymentForm from "../pages/Dashboard/Employment/EmploymentForm";
import EmploymentOpportunity from "../pages/Dashboard/Employment/EmploymentOpportunity";
import SelectedEmploymentOpportunity from "../pages/Dashboard/Employment/SelectedEmploymentOpportunity";
import Layout from "../pages/LandingPage/Layout";

const DashboardMain = (props) => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />} />
        <Route
          path="employmentopportunity"
          element={<EmploymentOpportunity />}
        />
        <Route
          path="selectedemploymentopportunity"
          element={<SelectedEmploymentOpportunity />}
        />
        <Route path="employmentform" element={<EmploymentForm />} />
        <Route
          path="contractoropportunity"
          element={<ContractorOpportunity />}
        />
        <Route path="contractorform" element={<ContractorForm />} />
        <Route
          path="selectedcontractoropportunity"
          element={<SelectedContractorOpportunity />}
        />

        <Route path="termcondition" element={<TermCondition />} />

        <Route exact path="*" element={<DashboardLayout />} />
      </Routes>
    </Router>
  );
};

export default DashboardMain;
