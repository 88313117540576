import React, { useState } from "react";
import { createBrowserHistory } from "history";
import AuthBackground from "../../components/LandingpageComp/Auth/AuthBackground";
import { whiteLogo, blueLogo } from "../../constant";
import { useSelector, useDispatch } from "react-redux";
import { forgotPassword } from "../../redux/actions/AuthAction";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { toast } from "react-toastify";

function CreatePassword() {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const Location = useLocation();
  const history = createBrowserHistory();
  const [newPassword, setNewPassword] = useState("");
  const [reNewPassword, setReNewPassword] = useState("");
  const [email, setEmail] = useState("");
  const [otpCode, setOtpCode] = useState("");
  const [loading, setLoading] = useState(false);

  console.log({ Location });
  const NewPasswordHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!newPassword || !reNewPassword) {
      toast.error("Please Fill all fields");
      setLoading(false);
      return;
    }
    if (newPassword !== reNewPassword) {
      toast.error("Please Enter Same password and Confirm Password");
      setLoading(false);
      return;
    }
    let data = {
      email: Location?.state?.email,
      code: Location?.state?.otpCode,
      password: newPassword,
      confirm_password: reNewPassword,
    };
    console.log(data);
    // let send = await dispatch(forgotPassword(data));
    // Navigate("/login");
    // window.location.href = "/login";
    setTimeout(async () => {
      let send = await dispatch(forgotPassword(data));
      setLoading(false);
      Navigate("/login");
      // history.push("/login");
    }, 600);
  };

  return (
    <>
      <section className="createpassword">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 p-0">
              <AuthBackground />
            </div>
            <div className="col-lg-6 p-0">
              <div className="dajaFrom">
                <div className="form-Flex">
                  <div className="logoDv">
                    <figure>
                      <img src={blueLogo} alt="" />
                    </figure>
                  </div>
                  <h2 className="title blueish">Forget Password</h2>
                  <div className="textDv">
                    <h4 className="subtext authText">
                      Create You New Password
                    </h4>
                    {/* <!-- <h4 className="bld"><strong>Create a new Password</strong></h4> --> */}
                  </div>
                  <form>
                    {/* <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Code"
                        value={otpCode}
                        onChange={(e) => setOtpCode(e.target.value)}
                        required
                      />
                    </div> */}

                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="New Password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Re-enter New Password"
                        value={reNewPassword}
                        onChange={(e) => setReNewPassword(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <button
                        className="form-control btn"
                        type="submit"
                        onClick={(e) => NewPasswordHandler(e)}
                        disabled={loading}
                      >
                        {loading ? "Loading..." : "Submit"}
                      </button>
                    </div>
                    <div className="privacyDv">
                      <h5>
                        By Creating Account you are agree to our{" "}
                        <Link to="/termcondition" className="blueish">
                          Terms & Conditions
                        </Link>
                      </h5>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CreatePassword;
