import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import moment from "moment";

function EmploymentSearchCard(props) {
  const { item, index } = props;
  const Navigate = useNavigate();
  console.log(item);
  return (
    <>
      <div className="col-md-12">
        <div className="search-Job-container">
          <div className="row">
            <div className="col-md-8 col-sm-8">
              <div className="feature-tag">Featured</div>
              <p className="job-title">{item?.title}</p>
              <p className="job-desc">{item?.description}</p>
            </div>
            <div className="col-md-4 col-sm-4">
              <p className="job-address">{item?.country?.name}</p>
              <p className="job-address">
                {moment(item?.updated_at).format("MMMM Do YYYY, h:mm a")}
              </p>
              <div className="searchjob-icon">
                <FontAwesomeIcon icon={faStar} />
              </div>
              <div className="employment-btnContainer">
                <div>
                  <button
                    type="button"
                    className="btn btn-primary Searchjob-btn mb-2"
                    style={{ width: "unset" }}
                    onClick={() => {
                      Navigate("/selectedemploymentopportunity", {
                        state: item,
                      });
                    }}
                  >
                    View
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    className="btn btn-primary Searchjob-btn mb-2"
                    style={{ width: "unset" }}
                    onClick={() => Navigate("/employmentform")}
                  >
                    Apply Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmploymentSearchCard;
