import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { ImageUrl } from "../../network/ApiUrl";

function ServiceCard(props) {
  const { item, index, serviceId, selectHandler } = props;
  return (
    <>
      <div className="col-md-4">
        <div
          className={`${
            item?.id == serviceId ? "serviceCard bg-dark " : "serviceCard  "
          }`}
          key={item?.id}
          onClick={() => selectHandler(item)}
        >
          <div className="serviceCard-img">
            <img src={`${ImageUrl}/${item?.icon}`} className="image-fluid" />
          </div>
          <p className="serviceTitle">{item?.name}</p>
          <div>
            <p className="serviceDescription">{item?.description}</p>
            <p className="serviceDescription mt-1">${item?.price}</p>
          </div>
        
          {/* <div className="icon-container">
            <FontAwesomeIcon icon={faArrowRight} className="service-icon" />
          </div> */}
        </div>
      </div>
    </>
  );
}

export default ServiceCard;
