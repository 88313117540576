import React, { useEffect, useState } from "react";
import ServicesCard from "../../components/LandingpageComp/Services/ServicesCard";
import { offer1, offer2, offer3, offer4, offer5, offer6 } from "../../constant";
import { useSelector } from "react-redux";
import { GetAllService } from "../../network/Network";

function ServicesSection() {
  const serviceData = useSelector((state) => state.AuthReducer.services);
  console.log(serviceData);

  const [allService, setAllService] = useState([]);

  //  == All Services ==
  useEffect(() => {
    GetAllService()
      .then((res) => {
        console.log(res);
        setAllService(res?.data?.response?.data?.data);
        // dispatch(AllServices(res?.data?.response?.data?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <section className="section pb-5" id="services">
        <div className="container">
          <div className="row justify-content-center text-center mb-5">
            <div className="col-md-5">
              <h4 className="section-heading text-black font-weight-bold">
                {/* We Offer Every Kind of Delivery Service */}
                Daja Is Your Personalized, One-Stop Nationwide Delivery Solution
              </h4>
            </div>
            <div className="col-md-12 text-center">
              <p className="text-black mt-2 text-center d-block w-50 ml-auto mr-auto">
                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam */}
                We use our exclusive mix of delivery services to provide a budget-friendly solution to match your business’ ever-changing demands. Take a look at our services below
              </p>
            </div>
          </div>
          <div className="deliver-service-section">
            <div className="row">
              {allService?.map((item, index) => {
                return <ServicesCard item={item} index={index} />;
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ServicesSection;
