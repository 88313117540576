import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faTrashAlt,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { visa } from "../../../constant";

function PaymentMethodCard(props) {
  const { DeletePaymentMethod, item, index, DefaultCardHandler } = props;
  console.log(item);

  return (
    <>
      <div className="row mb-4" key={item?.id}>
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-2">
              <FontAwesomeIcon
                icon={faCheckCircle}
                className={`${
                  item?.is_default == "1" ? "check-Icon" : "check-Icon2"
                }`}
              />
            </div>
            <div className="col-md-4">
              <img src={visa} alt="" className="image-fluid" />
            </div>
            <div className="col-md-6">
              <p className="paymentCard">My Personal Card</p>
              <p className="paymentCard-num">
                **********{item?.card_end_number}
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="flexBox-space">
            <div className="ml-4">
              <button
                type="button"
                className="btn btn-primary"
                style={{ width: "unset" }}
                onClick={(e) => DefaultCardHandler(e, item?.id)}
                disabled={item?.is_default != 0}
              >
                Set as Default
              </button>
            </div>
            <div>
              <FontAwesomeIcon
                icon={faTrashAlt}
                className="icon-delete"
                onClick={() => DeletePaymentMethod(item?.id)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentMethodCard;
