import React, { useState } from "react";
import { PostQuote } from "../../../network/Network";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

function GetQuote() {
  const token = useSelector((state) => state.AuthReducer.token);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [pickUpAddress, setPickUpAddress] = useState("");
  const [dropOffAddress, setDropOffAddress] = useState("");
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState("");

  const SubmitQuote = (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      !firstName ||
      !lastName ||
      !email ||
      !pickUpAddress ||
      !dropOffAddress ||
      !comment
    ) {
      toast.error("Please Enter All Fields");
      setLoading(false);
      return;
    }
    if (comment.length < 20) {
      toast.error("The message must be at least 20 characters");
      setLoading(false);
      return;
    }
    let data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      pick_up: pickUpAddress,
      drop_off: dropOffAddress,
      message: comment,
    };
    console.log(data);
    PostQuote(data, token)
      .then((res) => {
        setLoading(false);
        console.log(res);
        toast.success("Successfully send");
        setFirstName("");
        setLastName("");
        setEmail("");
        setPickUpAddress("");
        setDropOffAddress("");
        setComment("");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setFirstName("");
        setLastName("");
        setEmail("");
        setPickUpAddress("");
        setDropOffAddress("");
        setComment("");
      });
  };

  return (
    <>
      <div className="getQuote-Container">
        <p className="dashboard-title">Get A Quote</p>
        <div className="serviceCard">
          <form className="inputForm-padding">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Pickup Address"
                    value={pickUpAddress}
                    onChange={(e) => setPickUpAddress(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Drop Off Address"
                    value={dropOffAddress}
                    onChange={(e) => setDropOffAddress(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group mt-3">
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Write Your Comments Here..."
                    value={comment}
                    minlength={20}
                    onChange={(e) => setComment(e.target.value)}
                    required
                  />
                  <p className="comment-quoteP">
                    "The message must be at least 20 characters"
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="btn-container">
          <button
            type="button"
            className="btn btn-primary"
            onClick={(e) => {
              SubmitQuote(e);
            }}
            disabled={loading}
          >
            {loading ? "Loading..." : "Submit"}
          </button>
        </div>
      </div>
    </>
  );
}

export default GetQuote;
