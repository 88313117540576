// Define api end points here

export const AUTH = "/auth";
export const SIGNUP = "/store";
export const LOGIN = "/login";

export const SETTING = "/setting";
export const SHOWSTATE = "/showState";
export const FROGOT_PASSWORD = "/sendCode";
export const CHECK_CODE = "/checkCode";
export const RESEST = "/resetPassword";
export const PAYMENT = "/payment";
export const STORE_CARD = "/storeCard";
export const SHOW_CARD = "/showCard";
export const UPDATEDEFAULTCARD = "/updateDefaultCard";

export const REMOVE_CARD = "/removeCard";
export const QUOTESTORE = "/quoteStore";
export const USER = "/user";
export const UPDATE = "/update";
export const SHOWSERVICE = "/showService";
export const SHOW_COUNTRY = "/showCountry";
export const SHOWEMPLOYMENTS = "/showEmployments";
export const STORERESUMES = "/storeResumes";

export const SERVICES = "/services";
export const UPDATE_PROFILE = "/update-profile";

export const CUSTOMER = "/customer";
export const JOB = "/job";
export const STORE = "/store";

export const SHOWDEPART = "/showDepart";
export const SHOW_CONTRACTOR = "/showContractor";
export const ADDRESS = "/address";
export const SHOW = "/show";

export const STORECONTRACTOROPPORTUNITY = "/storeContractorOpportunity";
export const REPORT = "/report";

export const CHAT = "/chat";
export const CREATE = "/create";

export const GET = "/get";
export const MESSAGE = "/message";
export const SEND = "/send";

export const NOTIFICATION = "/notification";

export const RATING = "/rating";
export const INVOICE = "/invoice";
export const PAGE = "?page";

export const CHANGE_PASSWORD = "/changePassword";

export const TRACKING = "/tracking";
export const CUSTOMERTRACKING = "/customerTracking";

export const SHOW_PAGE = "/showPage";

// == End ==

export const PAYMENT_METHODS = "/payment-methods";

export const USER_SERVICES = "/user-services";
