export const ALERTS = "ALERTS";
export const SET_ALERTS = "SET_ALERTS";

export const LOGOUT = "LOGOUT";
export const LOGIN = "LOGIN";
export const SIGNUP = "SIGNUP";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_ERROR = "SIGNUP_REQUEST";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";

export const SET_PASSWORD_REQUEST = "SET_PASSWORD_REQUEST";
export const SET_PASSWORD_SUCCESS = "SET_PASSWORD_SUCCESS";
export const SET_PASSWORD_ERROR = "SET_PASSWORD_ERROR";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";
export const USER_TOKEN = "USER_TOKEN";

export const JOBS = "JOBS";
export const EDIT_PROFILE = "EDIT_PROFILE";
export const All_PAYMENT_METHOD = "All_PAYMENT_METHOD";
export const SERVICES = "SERVICES";
export const ADDRESS = "ADDRESS";

export const NOTIFICATION = "NOTIFICATION";

export const ALL_CHAT = "ALL_CHAT";
export const SET_NEW_MESSAGE = "SET_NEW_MESSAGE";

export const All_JOB_DATA = "All_JOB_DATA";

export const ALL_STATES_DATA = "ALL_STATES_DATA";
export const AUTO_ADDRESS = "AUTO_ADDRESS";

export const ALL_COUNTRY = "ALL_COUNTRY";

export const Term_Condition = "Term_Condition";
