import React, { useState, useEffect } from "react";
import { visa, card2, card3, card4, card5 } from "../.././../constant";
import { useLocation, useNavigate } from "react-router-dom";
import StripeCheckout from "react-stripe-checkout";
import { useDispatch, useSelector } from "react-redux";
import {
  AddPaymentMethods,
  DeletePaymentMethodsApi,
  GetAllPaymentMethods,
  PostPaymentDefaultCard,
} from "../../../network/Network";
import { toast } from "react-toastify";
import PaymentMethodCard from "../../../components/DashboardComp/Payment/PaymentMethodCard";
import { AllPaymentMethod } from "../../../redux/actions/AuthAction";
import { SpinnerCircular } from "spinners-react";

function PaymentMethod() {
  const Navigate = useNavigate();
  let dispatch = useDispatch();
  const Location = useLocation();
  const Token = useSelector((state) => state.AuthReducer.token);
  const [paymentData, setPaymentData] = useState([]);
  const [token_visa, setToken_visa] = useState("");
  const [paymentLoading, setPaymentLoading] = useState(false);

  const [spinLoad, setSpinLoad] = useState(false);

  console.log(Location?.state);

  //== stripe Token add card ==
  const onToken = (token) => {
    console.log("tok", token);
    console.log(Token);
    setToken_visa(token?.id);
    let data = {
      source: token?.id,
    };
    if (data) {
      setSpinLoad(true);
      AddPaymentMethods(data, Token)
        .then((res) => {
          console.log(res);
          dispatch(AllPaymentMethod(res?.data?.response?.data));
          setPaymentData(res?.data?.response?.data);
          setSpinLoad(false);
        })
        .catch((err) => {
          console.log(err);
          setSpinLoad(false);
        });
    } else {
      toast.error("something went wrong");
    }
  };

  //== Get All Card ==
  useEffect(() => {
    setSpinLoad(true);
    GetAllPaymentMethods(Token)
      .then((res) => {
        console.log(res?.data?.response?.data);
        dispatch(AllPaymentMethod(res?.data?.response?.data));
        // paymentData?.slice(res?.data?.response?.data?.length, 1);
        setPaymentData(res?.data?.response?.data);
        setSpinLoad(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinLoad(false);
      });
  }, []);

  // == Remove card ==
  const DeletePaymentMethod = (id, index) => {
    setPaymentLoading(true);
    console.log(id);
    console.log(Token);
    let data = { id: id };
    DeletePaymentMethodsApi(data, Token)
      .then((res) => {
        console.log(res?.data?.response?.data);
        paymentData?.splice(index, 1);
        setPaymentData(res?.data?.response?.data);
        setPaymentLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setPaymentLoading(false);
      });
  };

  const DefaultCardHandler = (e, id) => {
    e.preventDefault();
    // setPaymentLoading(true);
    let data = { id: id };
    console.log(id);
    PostPaymentDefaultCard(data, Token)
      .then((res) => {
        console.log(res);
        setPaymentData(res?.data?.response?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="PaymentMethod-Container">
        <p className="dashboard-title">Your Payment Information</p>
        <div className="serviceCard">
          <p className="subTitle">Saved payment methods</p>
          <hr />
          {spinLoad ? (
            <>
              <div className="loader-container">
                <SpinnerCircular size="80px" color="#FF9517" />
              </div>
            </>
          ) : (
            paymentData?.map((item, index) => {
              return (
                <PaymentMethodCard
                  item={item}
                  index={index}
                  DeletePaymentMethod={DeletePaymentMethod}
                  DefaultCardHandler={DefaultCardHandler}
                />
              );
            })
          )}
        </div>
        <div className="serviceCard mt-4">
          <div className="payment-gallery">
            <img src={visa} alt="" className="image-fluid" />
            <img src={card2} alt="" className="image-fluid" />
            <img src={card3} alt="" className="image-fluid" />
            <img src={card4} alt="" className="image-fluid" />
            <img src={card5} alt="" className="image-fluid" />
          </div>
          <div className="btn-container">
            <StripeCheckout
              // amount = '10.00'
              name="Add Payment Card"
              currency="USD"
              panelLabel="Add Card"
              label="Add New Payment"
              // description={`Order of ${computeQuantity(cart)} items!`}
              // image='LINKTOIMAGE'
              stripeKey="pk_test_51Htt4kBWz16oBSwkY9pAtAjnUTMRJgTO2IT9kAFvhOWAr7azMnMD5ArGoYDVUVIw0j592abv5KvRm2HCAuw4p5Zt00415ahcE4"
              token={onToken}
            >
              <button type="button" className="btn btn-primary">
                Add Card
              </button>
            </StripeCheckout>
          </div>
        </div>
        {Location?.state !== null ? (
          <div className="btn-container">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() =>
                Navigate("/dashboard/orderinformation", {
                  state: Location?.state,
                })
              }
            >
              View Order
            </button>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default PaymentMethod;
