import React, { useState } from "react";
import { whiteLogo, blueLogo } from "../../constant";
import AuthBackground from "../../components/LandingpageComp/Auth/AuthBackground";
import { createBrowserHistory } from "history";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { forgotPasswordEmailApi } from "../../network/Network";
import {
  forgotPassword,
  forgotPasswordEmail,
} from "../../redux/actions/AuthAction";
import { Link, useNavigate } from "react-router-dom";

function ForgetPassword() {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const history = createBrowserHistory();

  const ForgetPasswordHandler = async (e) => {
    e.preventDefault();
    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      toast.error("please enter correct email");
      setLoading(false);
      return;
    }
    // if (!email) {
    //   toast.error("please enter correct email");
    // }
    else {
      let data = { email: email };
      console.log(data);
      setTimeout(async () => {
        setLoading(false);
        let send = await dispatch(forgotPasswordEmail(data));
        Navigate("/otpverification", { state: email });
        // window.location.href = "/otpverification";
      }, 600);
    }
  };

  return (
    <>
      <section className="forget">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 p-0">
              <AuthBackground />
            </div>
            <div className="col-lg-6 p-0">
              <div className="dajaFrom">
                <div className="form-Flex">
                  <div className="logoDv">
                    <figure>
                      <img src={blueLogo} alt="" />
                    </figure>
                  </div>
                  <h2 className="title blueish">Forget Password</h2>
                  <div className="textDv">
                    <h4 className="subtext authText">
                      Please Enter Your Email Below
                    </h4>
                    <h4 className="bld authText-sub">
                      <strong>We will send you a recovery code</strong>
                    </h4>
                  </div>
                  <form>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <button
                        className="form-control btn"
                        type="submit"
                        onClick={(e) => {
                          ForgetPasswordHandler(e);
                          // history.push("/otpverification");
                        }}
                        disabled={loading}
                      >
                        {loading ? "Loading..." : "Submit"}
                      </button>
                    </div>
                    <div className="privacyDv">
                      <h5>
                        By Creating Account you are agree to our{" "}
                        <Link to="/termcondition" className="blueish">
                          Terms & Conditions
                        </Link>
                      </h5>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ForgetPassword;
