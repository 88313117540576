import React, { useEffect, useState } from "react";
import "./App.css";
import "./assets/css/Style.css";
import "./assets/css/Landingpage.css";
import "./assets/css/Signup.css";
import "./assets/css/Login.css";
import "./assets/css/ForgetPassword.css";
import "./assets/css/OtpVerification.css";
import "./assets/css/CreatePassword.css";
import "./assets/css/DashboardLayout.css";
import "./assets/css/DashboardDesign.css";
import "./assets/css/Chat.css";
import Layout from "./pages/LandingPage/Layout";
import AuthRoutes from "./routes/AuthRoutes";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DashboardLayout from "./pages/Dashboard/DashboardLayout/DashboardLayout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DashboardMain from "./routes/DashboardMain";
import Alert from "./pages/Alert";
import { useSelector, useDispatch } from "react-redux";
import {
  GetAllState,
  GetCountry,
  GetNotification,
  TermCondition,
} from "./network/Network";
import {
  AllCountry,
  AllStates,
  NotificationApi,
  TermConditionStore,
} from "./redux/actions/AuthAction";

function App() {
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => state.AuthReducer.isLogin);
  const token = useSelector((state) => state.AuthReducer.token);
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  let limit = 10;

  useEffect(() => {
    GetCountry()
      .then((res) => {
        console.log(res);
        dispatch(AllCountry(res?.data?.response?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    GetAllState()
      .then((res) => {
        console.log("STATE", res);
        dispatch(AllStates(res?.data?.response?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    TermCondition()
      .then((res) => {
        console.log(res);
        dispatch(TermConditionStore(res?.data?.response?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //  == Get Notification ==
  useEffect(() => {
    GetNotification(currentPage, token)
      .then((res) => {
        console.log(res);
        const total = res?.data?.response?.data?.total;
        setPageCount(Math.ceil(total / limit));
        // setNotification(res?.data?.response?.data?.data);
        dispatch(NotificationApi(res?.data?.response?.data?.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      {isLogin ? (
        <>
          <DashboardMain />
          <ToastContainer />
        </>
      ) : (
        <>
          <AuthRoutes />
          <ToastContainer />
        </>
      )}
      <Alert />
    </>
  );
}

export default App;
