import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { blueLogo } from "../../constant";
import AuthBackground from "./Auth/AuthBackground";
import { useSelector } from "react-redux";

function TermCondition() {
  const isLogin = useSelector((state) => state.AuthReducer.isLogin);
  const termConditionData = useSelector(
    (state) => state.AuthReducer.termCondition
  );

  return (
    <>
      <section className="singnin">
        {termConditionData?.map((item, index) => {
          return (
            <>
              {item?.slug === "term-condition" ? (
                <div className="container-fluid" key={index}>
                  <div className="row">
                    <div className="col-lg-6 p-0">
                      <AuthBackground />
                    </div>
                    <div className="col-lg-6 p-0">
                      <div className="dajaFrom">
                        <div className="form-Flex">
                          <div className="logoDv">
                            <figure>
                              <Link to="/">
                                <img src={blueLogo} alt="" />
                              </Link>
                            </figure>
                          </div>

                          <div className="memberLink pb-3  authText">
                            <h2 className="terms">{item?.title}</h2>

                            <div className="TermCondition">
                              <p>{item?.content}</p>
                            </div>
                            {isLogin ? (
                              <p>
                                <Link to="/" className="blueish termLink">
                                  Back to Home
                                </Link>
                              </p>
                            ) : (
                              <p>
                                <Link to="/login" className="blueish termLink">
                                  Login
                                </Link>
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </>
          );
        })}
      </section>
    </>
  );
}

export default TermCondition;
