import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import {
  Link,
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { CreateChat, GetTracking } from "../../../network/Network";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

function OrderTracking() {
  const Location = useLocation();
  const Navigate = useNavigate();
  const Token = useSelector((state) => state.AuthReducer.token);
  console.log(Location);
  const selectedJob = Location?.state;
  const [trackingNum, setTrackingNum] = useState("");
  const [loading, setLoading] = useState(false);

  // GetTracking
  const SubmitTrack = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setLoading(true);
      if (!trackingNum) {
        toast.error("Please enter Tracking id");
        setLoading(false);
        return;
      }
      GetTracking(trackingNum, Token)
        .then((res) => {
          console.log(res);
          console.log(res.data?.statusCode == "200");
          toast.success(res?.data?.message);
          if (res.data?.statusCode == "200") {
            Navigate("/dashboard/ridertracking", {
              state: { data: selectedJob },
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  return (
    <>
      <div className="orderTracking-Container">
        <p className="dashboard-title">Order Tracking </p>
        <div className="serviceCard">
          <form>
            <div className="form-group ordertrack-input">
              <input
                type="text"
                className="form-control"
                placeholder="Write Your Tracking Number"
                value={trackingNum}
                onChange={(e) => setTrackingNum(e.target.value)}
                onKeyDown={SubmitTrack}
                required
              />
            </div>
          </form>
          <div className="row mt-5">
            <div className="col-md-2 col-sm-2 col-2">
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="orderTrack-icon"
              />
            </div>
            <div className="col-md-10 col-sm-10 col-10">
              <p className="tracking-points">
                Job Status:{" "}
                {selectedJob?.status === "In Progress"
                  ? "Job Start"
                  : selectedJob?.status}
              </p>
            </div>

            {/* <div className="col-md-2 col-sm-2 col-2">
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="orderTrack-icon"
              />
            </div>
            <div className="col-md-10 col-sm-10 col-10">
              <p className="tracking-points">Waiting for Driver Assignment</p>
            </div> */}
            <div className="col-md-2 col-sm-2 col-2">
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="orderTrack-icon"
              />
            </div>
            <div className="col-md-10 col-sm-10 col-10">
              <p className="tracking-points">
                {/* Driver Assigned */}
                <Link to={`/dashboard/chat`} state={{ data: selectedJob }}>
                  Chat With Driver
                </Link>
              </p>
            </div>
            <div className="col-md-2 col-sm-2 col-2">
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="orderTrack-icon"
              />
            </div>
            <div className="col-md-10 col-sm-10 col-10">
              <p className="tracking-points">
                {/* Order Picked Up from location */}
                <Link
                  to={`/dashboard/ridertracking`}
                  state={{ data: selectedJob }}
                >
                  Track Rider
                </Link>
              </p>
            </div>
            {/* <div className="col-md-2 col-sm-2 col-2">
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="orderTrack-icon"
              />
            </div>
            <div className="col-md-10 col-sm-10 col-10">
              <p className="tracking-points">Order On Way</p>
            </div> */}
            {selectedJob?.status === "Completed" ||
            selectedJob?.status === "Canceled" ? (
              <>
                <div className="col-md-2 col-sm-2 col-2">
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="orderTrack-icon"
                  />
                </div>
                <div className="col-md-10 col-sm-10 col-10">
                  <p className="tracking-points">
                    {/* Order Picked Up from location */}
                    <Link
                      to={`/dashboard/driverrating`}
                      state={{ data: selectedJob }}
                    >
                      Give Reviews
                    </Link>
                  </p>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
}

export default OrderTracking;
