import React, { useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../../components/LandingpageComp/Navbar/Navbar";
import { useSelector, useDispatch } from "react-redux";
import { PostContractorOpportunity } from "../../../network/Network";
import { toast } from "react-toastify";

function ContractorForm() {
  const Navigate = useNavigate();
  const Location = useLocation();
  console.log(Location?.state);
  const selectedContractorData = Location?.state;
  const Token = useSelector((state) => state.AuthReducer.token);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [homePhone, setHomePhone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [vehicleYear, setVehicleYear] = useState("");
  const [vehicleMaker, setVehicleMaker] = useState("");
  const [vehicleModel, setVehicleModel] = useState("");
  const [loading, setLoading] = useState(false);

  // == Apply Contractor Opt form Job ==
  const ApplyContractor = (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      !firstName ||
      !lastName ||
      !homePhone ||
      !phoneNumber ||
      !city ||
      !state ||
      !zip ||
      !vehicleYear ||
      !vehicleMaker ||
      !vehicleModel
    ) {
      toast.error("Please Enter All Fields");
      setLoading(false);
      return;
    }
    let data = {
      contractor_id: selectedContractorData?.id,
      first_name: firstName,
      last_name: lastName,
      mobile: homePhone,
      phone_no: phoneNumber,
      city: city,
      state: state,
      zip: zip,
      vehicle_year: vehicleYear,
      vehicle_make: vehicleMaker,
      vehicle_model: vehicleModel,
    };
    PostContractorOpportunity(data, Token)
      .then((res) => {
        console.log(res);
        toast.success(res?.data?.message);
        setFirstName("");
        setLastName("");
        setHomePhone("");
        setPhoneNumber("");
        setCity("");
        setState("");
        setZip("");
        setVehicleYear("");
        setVehicleMaker("");
        setVehicleModel("");
        setLoading(false);
        Navigate("/contractoropportunity");
        // setJobData(res?.data?.response?.data);
        // dispatch(AllServices(res?.data?.response?.data));
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <>
      <Navbar page={"employment"} />
      <div className="getQuote-Container mb-5 mt-5">
        <p className="dashboard-title mt-5">Contractor Opportunity </p>
        <div className="serviceCard">
          <p className="contractor-form-title">Apply in this Opprtunity</p>
          <hr />
          <form className="inputForm-padding mt-5">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Mobile Phone"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Home Phone "
                    value={homePhone}
                    onChange={(e) => setHomePhone(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Street Address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                  />
                </div>
              </div>
              {/* <div className="col-md-6">
                <div className="form-group">
                  <select
                    className="custom-select my-1 mr-sm-2 form-control"
                    id="inlineFormCustomSelectPref"
                    onChange={(e) => setCity(e.target.value)}
                  >
                    <option selected>City</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                  </select>
                </div>
              </div> */}
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Street Address"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="State"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Zip"
                    value={zip}
                    onChange={(e) => setZip(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Vehicle Year"
                    value={vehicleYear}
                    onChange={(e) => setVehicleYear(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Vehicle Make"
                    value={vehicleMaker}
                    onChange={(e) => setVehicleMaker(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Vehicle Model"
                    value={vehicleModel}
                    onChange={(e) => setVehicleModel(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="btn-container">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => ApplyContractor(e)}
                  disabled={loading}
                >
                  {loading ? "Loading.." : "Apply Now"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default ContractorForm;
